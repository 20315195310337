<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-form-group label="Enter link URL">
                    <b-form-input v-model="selectedValues.buttonURL" name="buttonURL" placeholder="https://example.com" />
                    <label> This is the web address that your button will link to </label>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="9">
                <p>Open link in new window</p>
            </b-col>
            <b-col md="3">
                <b-form-group>
                    <b-form-checkbox v-model="selectedValuelinkInNewWindow" name="linkInNewWindow" switch inline />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BFormCheckbox } from 'bootstrap-vue'

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BFormCheckbox,
        },
        name: 'ClickLinkSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray.setting;
                } else {
                    return [];
                }
            },
            
            /* GETS THE linkInNewWindow VALUE USING THE Props */
            selectedValuelinkInNewWindow: {
                get() {
                    return this.selectedValues.linkInNewWindow == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.linkInNewWindow = newValue == true ? 1 : 0;
                }
            }
        },
    }
</script>