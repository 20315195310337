<template>
    <div class="pt-1">
        <!-- BODY START -->
        <b-row>

            <!-- whoShouldSee -->
            <b-col cols="12">
                <h5 class="font-weight-bolder">Who should see this?</h5>
                <Select2 :options="targetListArray" ref="changeSelect" name="whoShouldSee" v-model="prop_siteSettingArray.setting.whoShouldSee" />
            </b-col>

            <!-- RULE ADD/EDIT BUTTON -->
            <b-col cols="12" md="12">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover.top="'Add new Rule'" variant="flat-primary" v-b-modal.modal-add-edit-rules @click="addNewRule = true">
                    <span class="text-nowrap">New</span>
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover.top="'Edit this rule'" variant="flat-primary" v-b-modal.modal-add-edit-rules v-show="prop_siteSettingArray.setting.whoShouldSee!='Everyone'" @click="addNewRule=false"> <!-- EDIT BUTTON SHOULD NOT DISPLAY ON EVERYONE AND OTHER -->
                    <span class="text-nowrap">Edit</span>
                </b-button>
            </b-col>
        </b-row>

        <add-edit-rules :prop_targetNamesObject="prop_siteSettingArray.target_names" :prop_selectedTargetingRule="!addNewRule ? selectedTargetingRule : 0" @newOption="insertNewData" /> <!-- FOR ADD NEW USER TO SITE -->
    </div>
</template>

<script>
    import { BRow, BCol, BFormTextarea, BCardText, BButton, VBTooltip } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import AddEditRules from './tagret-components/AddEditRules.vue'

    export default {
        directives: {
            Ripple,
            'b-tooltip': VBTooltip,
        },
        components: {
            BRow, BCol, BFormTextarea, BCardText, BButton, AddEditRules
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        emits: ['displayData'],
        data() {
            return {
                targetListArray: [],
                addNewRule: false,
            };
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedTargetingRule() {
                if (!_.get(this.prop_siteSettingArray.setting, 'whoShouldSee')) {
                    this.prop_siteSettingArray.setting.whoShouldSee = 'Everyone';
                }
                return this.prop_siteSettingArray.setting.whoShouldSee;
            }
        },
        watch: {
            prop_siteSettingArray(settingNewVal) {
                this.targetList(settingNewVal.target_names);
            }
        },
        methods: {
            /* FETCH TARGETING RULES FROM DB AND APPEND TO SELECT2 OPTIONS */
            targetList(targetNames) {
                this.targetListArray.push({
                    'id': 'Everyone',
                    'text': 'Everyone',
                })
                for (const list of targetNames) {
                    this.targetListArray.push({
                        'id': list.targetingId,
                        'text': list.nameWhoShouldSee,
                    });
                }
            },

            /* ADDED/UPDATED RULE RENDER TO SELECT2 BOX WHICH IS COME FROM CHILD MODAL COMPOPENT AND COMING THROUGH EMIT */
            insertNewData(newOptionData) {
                if (newOptionData.status) {
                    if (newOptionData.action == 'update') {
                        /* UPDATED RULE CHANGE TO SELECT2 OPTIONS */
                        this.$set(this.targetListArray, _.find(this.targetListArray, { id: newOptionData.targeting_id }), { id: newOptionData.targeting_id, text: newOptionData.rule_name });
                        _.find(this.targetListArray, { id: newOptionData.targeting_id }).text = newOptionData.rule_name;
                        this.prop_siteSettingArray.setting.whoShouldSee = newOptionData.targeting_id;

                    } else {
                        /* NEWLY ADDED RULE ADD/PUSH TO SELECT2 OPTIONS */
                        this.targetListArray.push({
                            'id': newOptionData.targeting_id,
                            'text': newOptionData.rule_name,
                        })
                        this.prop_siteSettingArray.target_names.push({
                            "targetingId": newOptionData.targeting_id,
                            "siteId": newOptionData.siteid,
                            "settingId": newOptionData.settingid,
                            "rulewhoShouldSee": newOptionData.rule_type,
                            "nameWhoShouldSee": newOptionData.rule_name
                        })
                    }
                }
            },
        },
    }
</script>