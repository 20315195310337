<template>
    <div class="pt-1">
        <!-- DISPLAY SELECTED STYLE SECTION : START -->
        <b-row>
            <b-col md="12">
            <b-media no-body v-show="!goalDataDivShow">
                <b-media-aside>
                    <b-avatar rounded size="42" variant="light-primary">
                        <font-awesome-icon class="fa-2x" :icon="selectedGoal.avatar" />
                    </b-avatar>
                </b-media-aside>
                <b-media-body>
                    <h6 class="transaction-title">
                        {{ selectedGoal.title }}
                    </h6>
                    <small>{{ selectedGoal.description }}</small>
                </b-media-body>
            </b-media>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="goalDataDivShow=true" v-show="!goalDataDivShow&&selectedGoal.title!='Split Screen'">
                <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                <span class="align-middle">Change Type</span>
            </b-button>
            </b-col>
        </b-row>
        <!-- DISPLAY SELECTED STYLE SECTION : END -->

        <!-- SELECTION OF STYLE SECTION : START -->
        <b-row>
            <b-col md="12">
        <div v-for="(goal, goalKey) in styleSubGoalWise" :key="goalKey" class="mb-1" v-show="goalDataDivShow">
            <b-media no-body class="cursor-pointer" @click="selectStyleEvent(goalKey, goal)">
                <b-media-aside>
                    <b-avatar rounded size="42" variant="light-primary">
                        <font-awesome-icon class="fa-2x" :icon="goal.avatar" />
                    </b-avatar>
                </b-media-aside>
                <b-media-body>
                    <h6 class="transaction-title">
                        {{ goal.title }}
                    </h6>
                    <small>{{ goal.description }}</small>
                </b-media-body>
            </b-media>
        </div>
    </b-col>
        </b-row>
        <!-- SELECTION OF STYLE SECTION : END -->

        <!-- CALLING DYNAMIC COMPONRNT CAPTURE STYLE WISE LIKE BAR, MODAL, SPLITSCREEN, PAGETAKEOVER, SIDEMODAL, SLIDEBAR, SLIDER : START -->
        <component :is="this.selectedGoal.componentName" v-if="prop_siteSettingArray" :prop_siteSettingArray="siteSettingArray.setting" v-show="!goalDataDivShow"></component>
        <!-- CALLING DYNAMIC COMPONRNT CAPTURE STYLE WISE LIKE BAR, MODAL, SPLITSCREEN, PAGETAKEOVER, SIDEMODAL, SLIDEBAR, SLIDER : END -->
    </div>
</template>

<script>
    import { BRow, BCol, BMediaBody, BMedia, BMediaAside, BAvatar, BButton } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import captureIcons from "@/libs/capture-Icons"
    import BarSetting from './style-components/BarSetting'
    import ModalSetting from './style-components/ModalSetting'
    import SliderSetting from './style-components/SliderSetting'
    import PageTakeOverSetting from './style-components/PageTakeOverSetting'
    import SideModalSetting from './style-components/SideModalSetting'
    import SplitScreenSetting from './style-components/SplitScreenSetting'
    import SlideBarSetting from './style-components/SlideBarSetting'

    export default {
        directives: {
            Ripple
        },
        components: {
            BRow, BCol, BMediaBody, BMedia, BMediaAside, BAvatar, BButton,
            BarSetting, ModalSetting, SliderSetting, PageTakeOverSetting, SideModalSetting, SplitScreenSetting, SlideBarSetting
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        computed: {
            siteSettingArray() {
                return this.prop_siteSettingArray;
            },
        },
        data() {
            return {
                selectedGoal: {
                    title: 'Bar',
                    avatar: 'fa-solid fa-left-right',
                    description: 'Show a bar at the top of your site',
                    componentName: 'barSetting',
                },
                goalDataDivShow: false,
                styleSubGoalWise: _.pick(this.styleTypeIcons, ['1', '2', '3', '4', '6']),
            }
        },
        setup() {
            const { styleTypeIcons } = captureIcons();
            return {
                styleTypeIcons
            }
        },
        methods: {
            /* CHANGE STYLE OBJECT SUBGOAL WISE */
            selectStyleEvent(goalKey, goalData) { 
                if (this.styleSubGoalWise[goalKey] == undefined) { /* IF SELECTED STYLETYPE NOT IN STYLE GOAL OBJECT THEN CHOOSE 1ST ONE FROM THAT OBJECT */
                    goalKey = Object.keys(this.styleSubGoalWise)[0];
                    goalData = Object.values(this.styleSubGoalWise)[0];
                }
                this.$set(this.prop_siteSettingArray.setting, 'styleType', goalKey);
                this.goalDataDivShow = false
                this.$set(this.selectedGoal, 'title', goalData.title);
                this.$set(this.selectedGoal, 'description', goalData.description);
                this.$set(this.selectedGoal, 'avatar', goalData.avatar);
                this.$set(this.selectedGoal, 'componentName', goalData.componentName);
            },
            /* CHANGE STYLE OBJECT SUBGOAL WISE */
            styleSubGoalWiseMethod(subGoalId) { 
                if (subGoalId == 7) {
                    this.styleSubGoalWise = _.pick(this.styleTypeIcons, '5');
                } else if (subGoalId == 5) {
                    this.styleSubGoalWise = _.pick(this.styleTypeIcons, ['1', '2', '3', '4', '6', '7']);
                } else {
                    this.styleSubGoalWise = _.pick(this.styleTypeIcons, ['1', '2', '3', '4', '6']);
                }
            }
        },
        watch: {
            prop_siteSettingArray: {
                handler(settingNewVal) {
                    this.styleSubGoalWiseMethod(settingNewVal.setting.subGoal); /* CHANGE STYLE OBJECT SUBGOAL WISE */
                    if (settingNewVal.setting.subGoal == 7) {
                        this.selectStyleEvent('5', this.styleTypeIcons[5]); /* IF SUBGOAL IS FORTUNEWHEEL SELECTED THEN STYLE ONLY AVAILABLE IS SPITSCREEN AND IT IS AUTO SELECTED */
                    } else {
                        this.selectStyleEvent(settingNewVal.setting.styleType, this.styleTypeIcons[settingNewVal.setting.styleType]); /* AUTO SELECTED STYLE WHICH IS STORED IN DATABASE */
                    }
                },
                deep: true,
            },
        },
    }
</script>