<template>
    <div>
        <!-- CALLING CONTACT ADD/EDIT SECTION WHICH IS ALSO USE IN CONTACTS MODULE : START -->
        <contact-add-edit-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :prop_editContactListObject="editContactListObject" @refetch-data="getContactList()" />
        <!-- CALLING CONTACT ADD/EDIT SECTION WHICH IS ALSO USE IN CONTACTS MODULE : END -->

        <!-- collectFromVisitors -->
        <b-row>
            <b-col md="12">
                <b-form-group label="What do you want to collect from visitors?" label-for="collectFromVisitors">
                    <validation-provider name="collectFromVisitors" rules="required">
                        <Select2 v-model="selectedValuesSetting.collectFromVisitors" :options="emailTypeOptions" name="collectFromVisitors" :settings="{ dropdownAutoWidth: false }" />
                    </validation-provider>
                </b-form-group>
            </b-col>

            <!-- CONTACT ADD/EDIT/DELETE -->
            <!-- UI RELATED CHANGES -->
            <b-col cols="12" md="12">
                <b-button variant="flat-primary" @click="addContactList()" class="col-4">
                    <span class="text-nowrap">Add</span>
                </b-button>
                <b-button variant="flat-primary" @click="editContactList()" class="col-4">
                    <span class="text-nowrap">Edit</span>
                </b-button>
                <b-button variant="flat-primary" @click="deleteContactList(selectedValuesSetting.emailsStored)" class="col-4">
                    <span class="text-nowrap">Delete</span>
                </b-button>
            </b-col>

            <!-- emailsStored -->
            <b-col md="12">
                <b-form-group label="Which contact list would you like your emails stored in?" label-for="emailsStored">
                    <validation-provider name="emailsStored" rules="required">
                        <Select2 v-model="selectedValuesSetting.emailsStored" :options="emailStoredOptions" name="emailsStored" placeholder="Please select a list" />
                    </validation-provider>
                </b-form-group>
            </b-col>

            <!-- afterSubmit -->
            <b-col md="12">
                <b-form-group label="After visitor submits their email ..." label-for="afterSubmit">
                    <validation-provider name="afterSubmit" rules="required">
                        <Select2 v-model="selectedValuesSetting.afterSubmit" :options="afterSubmitOptions" name="afterSubmit" />
                    </validation-provider>
                </b-form-group>
            </b-col>

            <!-- defaultMessage|customMessage|visitorURL -->
            <b-col md="12">
                <b-form-group>
                    <b-form-input id="defaultMessage" name="defaultMessage" value="Thank you for signing up." placeholder="Thank you for signing up." v-if="selectedValuesSetting.afterSubmit==1" v-model="selectedValuesSetting.defaultMessage" :readonly="true" />
                    <b-form-input id="customMessage" name="customMessage" v-if="selectedValuesSetting.afterSubmit==2" v-model="selectedValuesSetting.customMessage" placeholder="Enter your message." />
                    <b-form-input id="visitorURL" name="visitorURL" v-if="selectedValuesSetting.afterSubmit==3" v-model="selectedValuesSetting.visitorURL" placeholder="Enter the URL for redirecting." />
                </b-form-group>
            </b-col>

            <!-- followupemail_status -->
            <b-col md="12">
                <b-form-group label="Follow Up By Email ?">
                    <b-form-radio-group v-model="selectedValuesSetting.followupemail_status" :options="followUpByEmailoptions" name="followupemail_status" @change="openFollowUpEmailOpen($event)" />
                </b-form-group>
            </b-col>
        </b-row>

        <!-- FOLLOWUP BY EMAIL MODAL : START -->
        <b-modal id="modal-followup-by-email" cancel-variant="outline-secondary" ok-title="Save" cancel-title="Close" title="Follow Up Email By?" size="lg">
            <b-form>
                <b-form-group>
                    <label for="followupsubject">Subject:</label>
                    <b-form-input type="text" name="followupsubject" id="followupsubject" placeholder="Email Subject" v-model="selectedValuesSetting.followup_subject" />
                </b-form-group>
                <b-form-group>
                    <label for="followupfrom">From</label>
                    <b-form-input type="email" name="followupfrom" id="followupfrom" placeholder="Email Address" v-model="selectedValuesSetting.followup_from" />
                </b-form-group>
                <b-form-group>
                    <label for="followupfrom">Message</label>
                    <quill-editor v-model="selectedValuesSetting.followupemail_text" :options="snowOption" />
                </b-form-group>
            </b-form>
        </b-modal>
        <!-- FOLLOWUP BY EMAIL MODAL : END -->
    </div>
</template>

<script>
    import { BRow, BCol, BForm, BFormInput, BFormRadioGroup, BFormGroup, BButton, BOverlay, BModal, VBModal, BFormSelect } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Ripple from 'vue-ripple-directive'
    import { required } from '@validations'
    import ContactAddEditNew from '@/views/contact/main-contact/ContactListAddNew'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import vSelect from 'vue-select'

    import { quillEditor } from 'vue-quill-editor'

    export default {
        directives: {
            Ripple,
            'b-modal': VBModal,
        },
        name: 'emailPhoneSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        components: {
            BRow, BCol, BForm, BFormInput, BFormRadioGroup, BFormGroup, BButton, BOverlay, ContactAddEditNew, BModal, quillEditor, BFormSelect,
            ValidationProvider, ValidationObserver, vSelect,
        },
        data() {
            return {
                followUpByEmailoptions: [
                    { value: 1, text: 'Yes' },
                    { value: 0, text: 'No' },
                ],
                emailTypeOptions: [
                    { text: 'Just email address', id: 1 },
                    { text: 'Name and email address', id: 2 },
                    { text: 'Name and phone number', id: 3 },
                    { text: 'Email and phone number', id: 4 },
                ],
                afterSubmitOptions: [
                    { text: 'Show default message', id: 1 },
                    { text: 'Show a custom message', id: 2 },
                    { text: 'Redirect the visitor to a url', id: 3 },
                ],
                emailStoredOptions: [],
                editContactListObject: {},
                isAddNewUserSidebarActive: false,
                defaultMessage: 'Thank you for signing up.',
                snowOption: {
                    theme: 'snow',
                },
                required,
            }
        },
        methods: {
            /* STORE EMAIL ARRAY USING GET CONTACT LIST */
            fetchContactList() {
                if (_.get(this.selectedValues, 'contact_list')) {
                    this.emailStoredOptions = [];
                    for (const contact_list of this.selectedValues.contact_list) {
                        this.emailStoredOptions.push({
                            'id': contact_list.contactListId,
                            'text': contact_list.contactListName,
                        });
                    }
                }
            },

            /* EDTI CONTACT LIST */
            editContactList() {
                if (_.get(this.selectedValues, 'contact_list')) {
                    var obj = _.find(this.selectedValues.contact_list, { contactListId: this.selectedValuesSetting.emailsStored });
                    this.editContactListObject = {};
                    this.editContactListObject.contact_list_id = obj.contactListId;
                    this.editContactListObject.list_name = obj.contactListName;
                    this.editContactListObject.store_type = obj.contactEmailService;
                    this.editContactListObject.drip_account_id = obj.dripAccountId;
                    this.editContactListObject.drip_campaign_id = obj.dripCampaignId;
                    this.isAddNewUserSidebarActive = true;
                }
            },

            /* BLANK DATA FOR ADD NEW CONTACT */
            addContactList() {
                this.editContactListObject = {};
                this.editContactListObject.list_name = '';
                this.editContactListObject.store_type = 1; /* In our site */
                this.editContactListObject.drip_account_id = '';
                this.editContactListObject.drip_campaign_id = '';
                this.isAddNewUserSidebarActive = true;
            },

            /* DELETE CONTAC */
            deleteContactList(contactListId) {
                var obj = _.find(this.selectedValues.contact_list, { contactListId: contactListId });
                this.$swal({
                    title: 'Are you sure?',
                    text: `All contact list in ${obj.contactListName} list will also delete!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.$contactAPI.delete({
                            'auth_id': this.$root.authUserObject.id ?? '',
                            'contact_list_id': contactListId ?? '',
                        }).then((response) => {
                            response = response.data;
                            if (response.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Deleted!',
                                    text: 'Contact list has been deleted.',
                                    customClass: {
                                        confirmButton: 'btn btn-success',
                                    },
                                })
                                this.getContactList();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'success',
                                        text: response.message,
                                    },
                                });
                            } else {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'danger',
                                        text: response.message,
                                    },
                                });
                            }
                        });
                    }
                })
            },

            /* GET CONTACT */
            getContactList() {
                this.isFetchingContactListArray = true;
                this.$contactAPI.get('get-contact-list', {
                    'auth_id': this.$root.authUserObject.id ?? '',
                }).then((response) => {
                    response = response.data;
                    if (response.status) {
                        if (_.get(this.selectedValues, 'contact_list')) {
                            this.selectedValues.contact_list = response.contact_list_array;
                        }
                        this.emailStoredOptions = [];
                        this.fetchContactList();
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Notification',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: response.message,
                            },
                        });
                    }
                    this.isFetchingDripCampaign = 0;
                    this.isFetchingContactListArray = false;
                });
            },

            /* OPEN EMAIL MODAL */
            openFollowUpEmailOpen(val) {
                if (val == 1) {
                    this.$bvModal.show('modal-followup-by-email');
                } else {
                    this.$bvModal.hide('modal-followup-by-email');
                }
            }
        },
        computed: {
            selectedValues() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },
            selectedValuesSetting() {
                this.selectedValues.setting.defaultMessage = this.defaultMessage;
                return this.selectedValues.setting;
            },
        },
        mounted() {
            this.fetchContactList();
        },
        watch: {
            selectedValues: {
                handler: function () {
                    this.fetchContactList();
                },
                deep: true
            }
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.setting.collectFromVisitors) || this.prop_siteSettingArray.setting.collectFromVisitors == 0) {
                this.prop_siteSettingArray.setting.collectFromVisitors = 1;
            }
            if (_.isEmpty(this.prop_siteSettingArray.setting.afterSubmit) || this.prop_siteSettingArray.setting.afterSubmit == 0) {
                this.prop_siteSettingArray.setting.afterSubmit = 1;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>