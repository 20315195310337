<template>
    <div>
        <fortune-contact-add-edit-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :prop_editContactListObject="editContactListObject" @refetch-data="getContactList()" />

        <div v-if="isModalOpen">
            <fortune-wheel-model :prop_fortuneWheelOptions="fortuneWheelOptionsObject" :prop_siteSettingArray="prop_siteSettingArray" />
        </div>

        <b-row>
            <b-col md="12">
                <span> Which contact list would you like your emails stored in? </span>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-button variant="flat-primary" @click="addFortuneContactList()">
                    <span class="text-nowrap">Add</span>
                </b-button>
                <b-button variant="flat-primary" @click="editFortuneContactList()">
                    <span class="text-nowrap">Edit</span>
                </b-button>
                <b-button variant="flat-primary" @click="deleteFortuneContactList(selectedValues.setting.emailsStored)">
                    <span class="text-nowrap">Delete</span>
                </b-button>
            </b-col>
            <b-col md="12">
                <b-form-group>
                    <validation-provider name="contact_list" rules="required">
                        <Select2 v-model="selectedValues.setting.emailsStored" placeholder="Please select a list" :options="this.contactListOptions" />
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="12">

                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-sm" type="button" v-b-modal.fortune-wheel-options-modal @click="fortueWheelOptionModel()">
                    <span>Set Fortune Wheel Options</span>
                </b-button>
            </b-col>
        </b-row>

        <br>
        <b-row>
            <!-- COLOR 1 -->
            <b-col md="12">
                <b-form-group label="color 1">
                    <b-input-group>
                        <b-form-input type="text" v-model="selectedValues.setting.fortuneWheelOptionColor[0]" name="color 1" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="selectedValues.setting.fortuneWheelOptionColor[0]" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- COLOR 2 -->
            <b-col md="12">
                <b-form-group label="color 2">
                    <b-input-group>
                        <b-form-input type="text" v-model="selectedValues.setting.fortuneWheelOptionColor[1]" name="color 2" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="selectedValues.setting.fortuneWheelOptionColor[1]" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- COLOR 3 -->
            <b-col md="12">
                <b-form-group label="color 3">
                    <b-input-group>
                        <b-form-input type="text" v-model="selectedValues.setting.fortuneWheelOptionColor[2]" name="color 3" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="selectedValues.setting.fortuneWheelOptionColor[2]" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- COLOR 4 -->
            <b-col md="12">
                <b-form-group label="color 4">
                    <b-input-group>
                        <b-form-input type="text" v-model="selectedValues.setting.fortuneWheelOptionColor[3]" name="color 4" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="selectedValues.setting.fortuneWheelOptionColor[3]" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup, BButton, BInputGroupAppend, BInputGroup, VBModal, BModal, BCardText } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Ripple from 'vue-ripple-directive'
    import FortuneContactAddEditNew from '@/views/contact/fortune-contact/ContactListAddNew'
    import FortuneWheelModel from '@/views/capture-setting/fortune-components/FortuneWheelModel'
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

    export default {
        directives: {
            Ripple,
            'b-modal': VBModal,
        },
        components: {
            BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup, BButton,
            ValidationProvider, ValidationObserver, BInputGroupAppend, BInputGroup, BModal, BCardText, FortuneContactAddEditNew, FortuneWheelModel
        },
        name: 'FortuneWheelSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        data() {
            return {
                contactList: [],
                editContactListObject: {},
                isAddNewUserSidebarActive: false,
                isFortuneWhileModalActive: false,
                contactListOptions: [],
                fortuneWheelOptionColor: {
                    0: '#9accd6',
                    1: '#e65c7a',
                    2: '#ebe7d3',
                    3: '#593b2e',
                },
                fortuneWheelOptionsObject: {},
                isModalOpen: false,
            }
        },
        methods: {
            /* STORE CONTACT ARRAY USING GET CONTACT LIST */
            fetchContactList() {
                this.contactListOptions = [];
                for (const contact_list of this.selectedValues.contact_list) {
                    this.contactListOptions.push({
                        'id': contact_list.contactListId,
                        'text': contact_list.contactListName,
                    });
                }

                /* IF CONTACT VALUE NOT FOUND THEN SHOW DEFAULT 0 FOR USED SAVE AND PUBLISH BUTTON */
                if(!_.find(this.contactListOptions, {id: this.selectedValues.setting.emailsStored})) {
                    this.selectedValues.setting.emailsStored = 0;
                }
            },

            /* ADD CONTACT */
            addFortuneContactList() {
                this.editContactListObject = {};
                this.editContactListObject.list_name = '';
                this.editContactListObject.store_type = 1; /* In our site */
                this.editContactListObject.drip_account_id = '';
                this.editContactListObject.drip_campaign_id = '';
                this.isAddNewUserSidebarActive = true;
            },

            /* EDIT CONTACT */
            editFortuneContactList() {
                if (_.get(this.selectedValues, 'contact_list')) {
                    var obj = _.find(this.selectedValues.contact_list, { contactListId: this.selectedValues.setting.emailsStored });
                    this.editContactListObject = {};
                    this.editContactListObject.contact_list_id = obj.contactListId;
                    this.editContactListObject.list_name = obj.contactListName;
                    this.editContactListObject.store_type = obj.contactEmailService;
                    this.editContactListObject.drip_account_id = obj.dripAccountId;
                    this.editContactListObject.drip_campaign_id = obj.dripCampaignId;
                    this.isAddNewUserSidebarActive = true;
                }
            },

            /* DELETE CONTACT */
            deleteFortuneContactList(contactListId) {
                var obj = _.find(this.selectedValues.contact_list, { contactListId: contactListId });
                this.$swal({
                    title: 'Are you sure?',
                    text: `All contact list in ${obj.contactListName} list will also delete!`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        this.$contactAPI.delete({
                            'auth_id': this.$root.authUserObject.id ?? '',
                            'contact_list_id': contactListId ?? '',
                        }).then((response) => {
                            response = response.data;
                            this.getContactList();
                            if (response.status) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'success',
                                        text: response.message,
                                    },
                                });
                            } else {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Notification',
                                        icon: 'BellIcon',
                                        variant: 'danger',
                                        text: response.message,
                                    },
                                });
                            }
                        });
                    }
                })
            },

            /* GET CONTACT */
            getContactList() {
                this.isFetchingContactListArray = true;
                this.$contactAPI.get('get-contact-list', {
                    'auth_id': this.$root.authUserObject.id ?? '',
                }).then((response) => {
                    response = response.data;
                    this.fetchContactList();
                    if (_.get(this.selectedValues, 'contact_list')) {
                        this.selectedValues.contact_list = response.contact_list_array;
                    }
                    this.isFetchingDripCampaign = 0;
                    this.isFetchingContactListArray = false;
                });
            },

            fortueWheelOptionModel() {
                this.isModalOpen = true;
                this.fortuneWheelOptions = {};
                this.$captureSettingAPI.post('fortune-wheel-options', {
                    'site_id': this.$root.appSiteObject.siteId ?? '',
                    'setting_id': this.$route.params.setting_id ?? '',
                }).then((response) => {
                    this.fortuneWheelOptionsObject = response.data;
                });
            },
        },
        /* created() {
            this.fortueWheelOptionModel();
        },  */     
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    if (this.prop_siteSettingArray.setting.fortuneWheelOptionColor) {
                        if (!_.isObject(this.prop_siteSettingArray.setting.fortuneWheelOptionColor)) {
                            let colors = JSON.parse(this.prop_siteSettingArray.setting.fortuneWheelOptionColor);
                            this.prop_siteSettingArray.setting.fortuneWheelOptionColor = colors;
                        }
                    } else {
                        this.prop_siteSettingArray.setting.fortuneWheelOptionColor = this.fortuneWheelOptionColor;
                    }
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },
        },
        watch: {
            selectedValues: {
                handler: function () {
                    this.fetchContactList();
                },
                deep: true
            }
        },
    }
</script>

<style type="scss">
    input[type='color'] {
        width: 5rem;
    }
</style>