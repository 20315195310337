<template>
    <div class="pt-1">
        <!-- BODY START -->
        <b-row>
            <b-col cols="12">

                <!-- ACTIVE CAPTURE SHEDULE DIV  -->
                <h4 class="font-weight-bolder">Active Capture Schedule</h4>
                <p>Select date & time to Active</p>
                <p>(EDT time) &nbsp;
                    <feather-icon icon="AlertCircleIcon" size="21" class="mr-50 cursor-pointer" v-b-modal.activeCaptureSchedule />
                </p>
                <b-form-group>
                    <b-input-group>
                        <VueCtkDateTimePicker id="pause-capture-schedule" v-model="settingValues.auto_enable_date_time" :noLabel="true" :noShortcuts="true" :format="'YYYY-MM-DD hh:mm a'" :right="true" :formatted="'MM-DD-YYYY - hh:mm A'" :minDate="currentDate" minute-interval="5" :behaviour="{time: {nearestIfDisabled: false}}" @is-shown="calendarOpen"/>
                    </b-input-group>
                </b-form-group>
                <br />
            </b-col>

            <!-- PAUSE CAPTURE SHEDULE DIV  -->
            <b-col cols="12">
                <h4 class="font-weight-bolder">Pause Capture Schedule</h4>
                <p>Select date & time to Pause</p>
                <p>(EDT time) &nbsp;
                    <feather-icon icon="AlertCircleIcon" size="21" class="mr-50 cursor-pointer" v-b-modal.pauseCaptureSchedule />
                </p>
                <b-form-group>
                    <b-input-group>
                        <VueCtkDateTimePicker id="active-capture-schedule" v-model="settingValues.auto_disable_date_time" :noLabel="true" :noShortcuts="true" :format="'YYYY-MM-DD hh:mm a'" :right="true" position="bottom" :formatted="'MM-DD-YYYY - hh:mm A'" :minDate="currentDate" minute-interval="5"  :behaviour="{time: {nearestIfDisabled: false}}" />
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <!-- ACTIVE CAPTURE SCHEDULE MODAL  -->
        <b-modal id="activeCaptureSchedule" title="Active Capture Schedule Note:" ok-only ok-title="Close" modal-class="modal-danger" ok-variant="danger">
            <b-card-text>
                <p>(1) Capture will never get activated if the date/time is not selected.</p>
                <p>(2) If capture is active and schedule is created for future date/time, then the Active capture will get paused and will get activated on schedule date/time.</p>
            </b-card-text>
        </b-modal>

        <!-- PAUSE CAPTURE SCHEDULE MODAL  -->
        <b-modal id="pauseCaptureSchedule" title="Pause Capture Schedule Note:" ok-only ok-title="Close" modal-class="modal-danger" ok-variant="danger">
            <b-card-text>
                <p>(1) It will never pause if the date/time is not selected.</p>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
    import { BRow, BCol, BAvatar, BButton, BModal, VBModal, BCardText, BInputGroup, BFormGroup, BInputGroupAppend } from 'bootstrap-vue'
    import moment from "moment"

    export default {
        directives: {
            'b-modal': VBModal,
        },
        components: {
            BRow, BCol, BAvatar, BButton, BModal, BCardText, BInputGroup, BFormGroup, BInputGroupAppend
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            settingValues() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray.setting;
                } else {
                    return [];
                }
            },
        },
        methods: {
                    calendarOpen() {
                        if (/mobi/i.test(navigator.userAgent)) {
                            document.getElementsByClassName("card-header")[0].style.zIndex = 999;
                        }
                    }
                },   
        data() {
            return {
                currentDate: moment().format("YYYY-MM-DD hh:mm a"),
                dateAndTime: null,
            }
        },
    }
</script>