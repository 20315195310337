<template>
    <div>
        <!-- modalAnimate -->
        <b-row>
            <b-col md="12">
                <b-form-group>
                    <span>Animations</span>
                    <b-form-checkbox name="sideModalAnimate" class="float-right" switch stacked v-model="selectedValueModalAnimate" />
                </b-form-group>
            </b-col>

            <!-- sideButtonPlacement -->
            <b-col md="12">
                <b-form-group label="Button Placement" label-for="sideButtonPlacement">
                    <Select2 name="sideButtonPlacement" :options="sideButtonPlacementOptions" v-model="selectedValues.sideButtonPlacement" />
                </b-form-group>
            </b-col>

            <!-- sideModalPlacement -->
            <b-col md="12">
                <b-form-group label="Modal Placement" label-for="sideModalPlacement">
                    <Select2 name="sideModalPlacement" :options="modalPlacementOptions" v-model="selectedValues.sideModalPlacement" />
                </b-form-group>
            </b-col>

            <!-- modalDisplay -->
            <b-col md="12">
                <b-form-group label="When does it display?" label-for="modalDisplay">
                    <Select2 name="sideModalDisplay" :options="barDisplayOptions" v-model="selectedValues.sideModalDisplay" />
                </b-form-group>
            </b-col>

            <!-- Animation Effect -->
            <b-col md="12">
                <b-form-group label="Animated Effect" label-for="animation_effect">
                    <b-form-select name="animation_effect" :options="animationEffectOptions" v-model="selectedValues.animation_effect" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect,
        },
        name: 'SideModalSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { sideButtonPlacementOptions, modalPlacementOptions, barDisplayOptions, animationEffectOptions } = captureOptions();
            return {
                sideButtonPlacementOptions, modalPlacementOptions, barDisplayOptions, animationEffectOptions
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (this.prop_siteSettingArray) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },

            /* GETS THE modal animate VALUE USING THE selectedValues(). */
            selectedValueModalAnimate: {
                get() {
                    return this.selectedValues.sideModalAnimate == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.sideModalAnimate = newValue == true ? 1 : 0;
                }
            },
        },
        data() {
            return {}
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.sideModalAnimate) || this.prop_siteSettingArray.sideModalAnimate == 0) {
                this.prop_siteSettingArray.sideModalAnimate = true;
            }
            if (_.isEmpty(this.prop_siteSettingArray.sideButtonPlacement)) {
                this.prop_siteSettingArray.sideButtonPlacement = 'top';
            }
            if (_.isEmpty(this.prop_siteSettingArray.sideModalPlacement)) {
                this.prop_siteSettingArray.sideModalPlacement = 'top';
            }
            if (_.isEmpty(this.prop_siteSettingArray.sideModalDisplay) || this.prop_siteSettingArray.sideModalDisplay == 0) {
                this.prop_siteSettingArray.sideModalDisplay = '1';
            }
            if (_.isEmpty(this.prop_siteSettingArray.animation_effect)) {
                this.prop_siteSettingArray.animation_effect = 'null';
            }
        },
    }
</script>