<template>
    <div class="pt-1">
        <!-- BODY START -->
        <b-row>
            <b-col cols="12">
                <h5 class="font-weight-bolder">Add Style</h5>
                <b-form-textarea v-if="prop_siteSettingArray.setting" v-model="prop_siteSettingArray.setting.iframe_css" id="textarea-default" placeholder="ex.  z-index : 9999 !important;" rows="3" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BFormTextarea } from 'bootstrap-vue'

export default {
    directives: {},
    components: {
        BRow, BCol, BFormTextarea,
    },
    props: {
        prop_siteSettingArray: {
            type: Object,
            default: () => { },
        }
    },
    data() {
        return {};
    },
}
</script>
