export default () => {
    /**
     * FOR USE OF CAPTURE STYLES IN MANAGE SITE MODULE
     * CALLING FROM: 
     * 1) appfrontend\src\views\report\mobile-vs-desktop-report\Index.vue
     * 2) appfrontend\src\views\manage-site\ManageSiteCapture.vue
     * 3) appfrontend\src\views\capture-setting\StyleDetails.vue
     */
    const styleTypeIcons = {
        1: {
            title: 'Bar',
            avatar: 'fa-solid fa-left-right',
            description: 'Show a bar at the top of your site',
            componentName: 'barSetting',
        },
        2: {
            title: 'Modal',
            avatar: 'fa-solid fa-credit-card',
            description: 'Pop up a window in the middle of the screen',
            componentName: 'modalSetting',
        },
        3: {
            title: 'Slider',
            avatar: 'fa-regular fa-credit-card',
            description: 'Show a small window on the bottom of your site',
            componentName: 'sliderSetting',
        },
        4: {
            title: 'Page Takeover',
            avatar: 'fa-solid fa-file-lines',
            description: 'Show a window that totally covers your webpage content',
            componentName: 'pageTakeOverSetting',
        },
        5: {
            title: 'Split Screen',
            avatar: 'fa-solid fa-table-columns',
            description: 'Split Screen web page design something like a card with left and rigth side',
            componentName: 'splitScreenSetting',
        },
        6: {
            title: 'Side Modal',
            avatar: 'fa-solid fa-bars-progress',
            description: 'On click of a button Pop up a window in the middle of the screen',
            componentName: 'sideModalSetting',
        },
        7: {
            title: 'Slide Bar',
            avatar: 'fa-solid fa-network-wired',
            description: 'On click of a bar Slide a window in the top of the screen',
            componentName: 'slideBarSetting',
        },
    };

    /**
     * FOR USE OF CAPTURE GOALS
     * CALLING FROM: 
     * 1) appfrontend\src\views\capture-setting\SettingDetails.vue
     * 2) appfrontend\src\views\manage-site\ManageSiteCapture.vue
     */
    const subGoalTypeIcons = {
        1: {
            title: 'Collect Email/Phone',
            description: "Use your Capture to collect visitors' email addresses",
            avatar: 'MailIcon', 
            avatarVariant: 'light-success',
            componentName: 'EmailPhoneSetting',
        },
        2: {
            title: 'Talk to visitors',
            description: 'Let mobile visitors call you with a click',
            avatar: 'PhoneIcon',
            avatarVariant: 'light-primary',
            componentName: 'talkToVisitorSetting',
        },
        3: {
            title: 'Social',
            description: 'Point visitors to your social media channels',
            avatar: 'Share2Icon',
            avatarVariant: 'light-info',
            componentName: 'SocialSetting',
        },
        4: {
            title: 'Click Link',
            description: 'Use your Capture to drive more traffic to a specific URL',
            avatar: 'MousePointerIcon',
            avatarVariant: 'light-warning',
            componentName: 'ClickLinkSetting',
        },
        5: {
            title: 'Announcement',
            description: 'Display a simple announcement to your visitors',
            avatar: 'Volume2Icon',
            avatarVariant: 'light-danger',
            componentName: 'AnnouncementSetting',
        },
        6: {
            title: 'Subscribe to Text App',
            description: 'Subscribe to Texting App',
            avatar: 'SmartphoneIcon',
            avatarVariant: 'light-success',
            componentName: 'SubscribeToTextAppSetting',
        },
        7: {
            title: 'Fortune Wheel',
            description: 'You have a chance to win a nice big fat discount. Are you ready?',
            avatar: 'GiftIcon',
            avatarVariant: 'light-info',
            componentName: 'FortuneWheelSetting',
        },
    };

    /**
     * FOR USE OF CAPTURE USES BELOW SOCIAL MEDIA TYPES
     * CALLING FROM: appfrontend\src\views\capture-setting\subgoal-components\SocialSetting.vue
     */
    const socialMedialShareTypeIcons = {
        1: {
            title: 'Tweet on Twitter',
            avatar: 'TwitterIcon',
            name: 'tweetTwitter',
        },
        2: {
            title: 'Follow on Twitter',
            avatar: 'TwitchIcon',
            name: 'followTwitter',
        },
        3: {
            title: 'Like on Facebook',
            avatar: 'FacebookIcon',
            name: 'likeFacebook',
        },
        4: {
            title: 'Share on LinkedIn',
            avatar: 'LinkedinIcon',
            name: 'shareLinkedIn',
        },
        5: {
            title: '+1 on Google+',
            avatar: 'TwitterIcon',
            name: 'google',
        },
        6: {
            title: 'Pin on Pinterest',
            avatar: 'TwitterIcon',
            name: 'pinPinterest',
        },
        7: {
            title: 'Follow on Pinterest',
            avatar: 'TwitterIcon',
            name: 'followPinterest',
        },
        8: {
            title: 'Share on Buffer',
            avatar: 'LifeBuoyIcon',
            name: 'shareBuffer',
        },
    };

    /**
     * FOR USE OF CAPTURE EDIT MENU LISTS AND THEIR ICONS
     * CALLING FROM: appfrontend\src\views\capture-setting\FiltersLeftSidebar.vue
     */
    const settingMenuIcons = {
        1: {
            title: 'Setting',
            avatar: 'SettingsIcon',
        },
        2: {
            title: 'Style',
            avatar: 'Edit3Icon',
        },
        3: {
            title: 'Schedule',
            avatar: 'WatchIcon',
        },
        4: {
            title: 'Text and Content',
            avatar: 'TypeIcon',
        },
        5: {
            title: 'Iframe CSS',
            avatar: 'CodeIcon',
        },
        6: {
            title: 'Targeting',
            avatar: 'EyeIcon',
        },
    };
    
    return {
        styleTypeIcons,
        subGoalTypeIcons,
        socialMedialShareTypeIcons,
        settingMenuIcons
    }
}