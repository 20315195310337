<template>
	<div class="sidebar-left">
		<div class="sidebar">
			<div class="sidebar-content todo-sidebar">

				<div class="todo-app-menu">
					<div class="add-task p-0">
						<!-- SEARCHBAR -->
						<h4 class="font-weight-bolder text-center text-primary bg-light-primary p-1">
							<feather-icon :icon="activeAvtar" size="21" class="mr-50" />
							<span>{{activeTab}}</span><br>
						</h4>
					</div>

					<vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area">
						<!-- LOADER -->
						<b-overlay :show="isFetchingSiteSettingArray" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

							<b-tabs card tabs fill :vertical="$isMobile() == false" content-class="px-1 py-0" :nav-wrapper-class="navClass" active-nav-item-class="" active-tab-class="" v-model="tabIndex">

								<!-- SETTING MENU DETAIL COMPONENT AND DETAIL : START -->
								<b-tab no-body :active="this.activeTab == settingMenuIcons[1].title" @click="activeTabFind(settingMenuIcons[1].title, settingMenuIcons[1].avatar)" :key="settingMenuIcons[1].title">
									<template #title>
										<feather-icon :icon="settingMenuIcons[1].avatar" size="21" class="text-center" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover="{ title: settingMenuIcons[1].title, placement: tooltipPosition}" />
									</template>

									<setting-details :prop_siteSettingArray="prop_siteSettingArray" />

								</b-tab>
								<!-- SETTING MENU DETAIL COMPONENT AND DETAIL : END -->

								<!-- STYLE MENU DETAIL COMPONENT AND DETAIL : START -->
								<b-tab no-body :active="this.activeTab == settingMenuIcons[2].title" @click="activeTabFind(settingMenuIcons[2].title, settingMenuIcons[2].avatar)">
									<template #title>
										<feather-icon :icon="settingMenuIcons[2].avatar" size="21" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover="{ title: settingMenuIcons[2].title, placement: tooltipPosition}"></feather-icon>
									</template>

									<style-details v-if="prop_siteSettingArray" :prop_siteSettingArray="prop_siteSettingArray" />

								</b-tab>
								<!-- STYLE MENU DETAIL COMPONENT AND DETAIL : END -->

								<!-- SCHEDULE MENU DETAIL COMPONENT AND DETAIL : START -->
								<b-tab no-body :active="this.activeTab == settingMenuIcons[3].title" @click="activeTabFind(settingMenuIcons[3].title, settingMenuIcons[3].avatar)">
									<template #title>
										<feather-icon :icon="settingMenuIcons[3].avatar" size="21" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover="{ title: settingMenuIcons[3].title, placement: tooltipPosition}"></feather-icon>
									</template>
									<schedule-details v-if="prop_siteSettingArray" :prop_siteSettingArray="prop_siteSettingArray"></schedule-details>
								</b-tab>
								<!-- SCHEDULE MENU DETAIL COMPONENT AND DETAIL : END -->

								<!-- TEXT AND CONTENT MENU DETAIL COMPONENT AND DETAIL : START -->
								<b-tab no-body :active="this.activeTab == settingMenuIcons[4].title" @click="activeTabFind(settingMenuIcons[4].title, settingMenuIcons[4].avatar)">
									<template #title>
										<feather-icon :icon="settingMenuIcons[4].avatar" size="21" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover="{ title: settingMenuIcons[4].title, placement: tooltipPosition}"></feather-icon>
									</template>

									<text-and-content-details v-if="prop_siteSettingArray" :prop_siteSettingArray="prop_siteSettingArray" />

								</b-tab>
								<!-- TEXT AND CONTENT MENU DETAIL COMPONENT AND DETAIL : END -->

								<!-- IFRAME CSS MENU DETAIL COMPONENT AND DETAIL : START -->
								<b-tab no-body :active="this.activeTab == settingMenuIcons[5].title" @click="activeTabFind(settingMenuIcons[5].title, settingMenuIcons[5].avatar)">
									<template #title>
										<feather-icon :icon="settingMenuIcons[5].avatar" size="21" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover="{ title: settingMenuIcons[5].title, placement: tooltipPosition}"></feather-icon>
									</template>

									<iframe-css-details v-if="prop_siteSettingArray" :prop_siteSettingArray="prop_siteSettingArray"> </iframe-css-details>

								</b-tab>
								<!-- IFRAME CSS MENU DETAIL COMPONENT AND DETAIL : END -->

								<!-- TARGETING MENU DETAIL COMPONENT AND DETAIL : START -->
								<b-tab no-body :active="this.activeTab == settingMenuIcons[6].title" @click="activeTabFind(settingMenuIcons[6].title, settingMenuIcons[6].avatar)">
									<template #title>
										<feather-icon :icon="settingMenuIcons[6].avatar" size="21" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover="{ title: settingMenuIcons[6].title, placement: tooltipPosition}"></feather-icon>
									</template>

									<targeting-details v-if="prop_siteSettingArray" :prop_siteSettingArray="prop_siteSettingArray"> </targeting-details>

								</b-tab>
								<!-- TARGETING MENU DETAIL COMPONENT AND DETAIL : END -->
							</b-tabs>

							<!-- MENU PREVIOUS AND NEXT : START -->
							<div class="text-center">
								<div class="mt-1 p-1 d-flex justify-content-between">
									<b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="warning" @click="tabIndex--" :disabled="tabIndex == 0">
										<feather-icon :icon="`ArrowLeftIcon`" size="18"></feather-icon>
									</b-button>
									<b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="warning" @click="tabIndex++" :disabled="tabIndex >= 5">
										<feather-icon :icon="`ArrowRightIcon`" size="18"></feather-icon>
									</b-button>
								</div>
							</div>
							<!-- MENU PREVIOUS AND NEXT : END -->

							<!-- FOR SAVE & PUBLISH AND CLOSE OR BACK BUTTON : START -->
							<b-row class="mx-0">
								<b-col md="6">
									<b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" block variant="outline-danger" @click="redirectToManageSite()">Back</b-button>
								</b-col>
								<b-col md="6">
									<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="saveAndPublishSite()">Save & Publish</b-button>
								</b-col>
							</b-row>
							<!-- FOR SAVE & PUBLISH AND CLOSE OR BACK BUTTON : END -->

						</b-overlay>
					</vue-perfect-scrollbar>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton, BListGroup, BListGroupItem, BRow, BCol, BTabs, BTab, VBTooltip, BOverlay } from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import captureIcons from "@/libs/capture-Icons"
	import SettingDetails from './SettingDetails'
	import StyleDetails from './StyleDetails'
	import ScheduleDetails from './ScheduleDetails'
	import TextAndContentDetails from './TextAndContentDetails'
	import IframeCssDetails from './IframeCssDetails'
	import TargetingDetails from './TargetingDetails'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		directives: {
			Ripple,
			'b-tooltip': VBTooltip,
		},
		components: {
			BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton, BListGroup, BListGroupItem, BRow, BCol, BTabs, BTab, VuePerfectScrollbar, BOverlay,
			SettingDetails, StyleDetails, ScheduleDetails, TextAndContentDetails, IframeCssDetails, TargetingDetails, ToastificationContent
		},
		props: {
			prop_siteSettingArray: {
				type: Object,
				default: () => { },
			}
		},
		data: function () {
			return {
				isFetchingSiteSettingArray: true,
				tabIndex: 1,
				activeTab: 'Setting',
				navClass: this.$isMobile() == false ? 'nav-vertical px-0 py-0' : 'px-0 py-0 sticky-top',
				activeAvtar: 'SettingsIcon',
				tooltipPosition: this.$isMobile() == false ? 'right' : 'top',
			};
		},
		methods: {

			/**
			 * FOR SAVE CAPTURE AND SEND OBJECT TO SERVER
			 */
			saveAndPublishSite() {
				let settingObject = this.prop_siteSettingArray.setting;
				var validation = true;

				/* VALIDATION : START */

				/* 1 = Collect email/phone */
				/* emailsStored FIELD VALIDATION */
				if (settingObject.subGoal == 1 && (_.isEmpty(settingObject.emailsStored) || settingObject.emailsStored == 0)) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please select Contact List');
					validation = false;
				}

				/* phoneNumber FIELD VALIDATION */
				if (settingObject.subGoal == 2 && _.isEmpty(settingObject.phoneNumber)) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please enter phone number');
					validation = false;
				}

				/* activeSocialMedia FIELD VALIDATION */
				if (settingObject.subGoal == 3 && _.isEmpty(settingObject.activeSocialMedia)) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please select social media channels');
					validation = false;
				}

				/* buttonURL FIELD VALIDATION */
				if (settingObject.subGoal == 4 && _.isEmpty(settingObject.buttonURL)) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please enter link URL');
					validation = false;
				}

				/* headline for announcement : FIELD VALIDATION */
				if (settingObject.subGoal == 5 && _.isEmpty(settingObject.captureBarSay)) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please enter headline');
					validation = false;
				}

				/* captureBarSay FIELD VALIDATION */
				if (_.includes([5, 6], settingObject.subGoal) && _.isEmpty(settingObject.captureBarSay)) {
					this.activeTab = this.settingMenuIcons[4].title;
					this.showToast('danger', 'Please enter headline');
					validation = false;
				}

				/* captureBarSay FIELD VALIDATION */
				if (settingObject.subGoal == 6 && _.isEmpty(settingObject.textapp_keyword)) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please select keyword');
					validation = false;
				}

				if (settingObject.subGoal == 7 && settingObject.emailsStored == 0) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please select contact list...!');
					validation = false;
				}

				if (settingObject.subGoal == 7 && _.isEmpty(settingObject.fortuneWheelOptions)) {
					this.activeTab = this.settingMenuIcons[1].title;
					this.showToast('danger', 'Please set fortune wheel options!');
					validation = false;
				}

				if(settingObject.subGoal == 7) {
					let fortuneWheelOptionsObject = JSON.parse(settingObject.fortuneWheelOptions);
					
					/* CHECK TITLE ROW IS EMPTY OR NOT */
					let mapTitleObject = Object.values(fortuneWheelOptionsObject.titles);
					let emptyTitleCheck = _.some(_.map(mapTitleObject), _.isEmpty);
					if (emptyTitleCheck) {
						this.showToast('danger', 'Please enter title first');
						validation = false;
					}

					/* winningInterval CHECK MORE THEN 2 VALUE REQUIRED */
					let mapWinningIntervalObject =  Object.values(fortuneWheelOptionsObject.winningInterval);
					let inputWinningIntervalNumber = '';
					_.map(mapWinningIntervalObject, function (value) {
						if (_.isEmpty(value)) {
							return null;
						} else {
							inputWinningIntervalNumber = value
							return value;
						}
					});
					if (!_.isEmpty(inputWinningIntervalNumber)) {
						if (inputWinningIntervalNumber <= 1) {
							this.showToast('danger', 'Please set minimun Winning Interval 2 or above.');
							validation = false;
						}
					}

					/* COUPONCODECHECK CHECK WINNIG INTERNAL VALUE EMPTY OR NOT */
					let couponCodeCheck =  Object.values(fortuneWheelOptionsObject.couponcodes);
					_.map(mapWinningIntervalObject, (item, itemKey) => {
						if (!_.isEmpty(item)) {
							if (_.isEmpty(couponCodeCheck[itemKey])) {
								this.showToast('danger', 'Please enter coupon code to Winning Interval rows');
								validation = false;
							}
						}
					});
				}

				/* whoShouldSee FIELD VALIDATION */
				if (_.isEmpty(settingObject.whoShouldSee)) {
					this.activeTab = this.settingMenuIcons[6].title;
					this.showToast('danger', 'Please select any one targeting rules.');
					validation = false;
				}

				/* sideButtonSay FIELD VALIDATION */
				if (settingObject.styleType == "6" && _.isEmpty(settingObject.sideButtonSay)) {
					this.activeTab = this.settingMenuIcons[4].title;
					this.showToast('danger', 'Please enter side button text');
					validation = false;
				}

				/* styleType = 7 : Slide Bar  */
				if (settingObject.styleType == "7") {
					if (_.isEmpty(settingObject.slideBarSay1)) {
						this.activeTab = this.settingMenuIcons[4].title;
						this.showToast('danger', 'Please enter headline1');
						validation = false;
					}

					if (_.isEmpty(settingObject.slideBarSay2)) {
						this.activeTab = this.settingMenuIcons[4].title;
						this.showToast('danger', 'Please enter headline2');
						validation = false;
					}

					if (_.isEmpty(settingObject.slideBarSay3)) {
						this.activeTab = this.settingMenuIcons[4].title;
						this.showToast('danger', 'Please enter headline3');
						validation = false;
					}

					if (_.isEmpty(settingObject.slideBarHtmlBlock1) && _.isEmpty(settingObject.slideBarHtmlBlock2) && _.isEmpty(settingObject.slideBarHtmlBlock3)) {
						this.activeTab = this.settingMenuIcons[4].title;
						this.showToast('danger', 'Please enter atlease one HTML block');
						validation = false;
					}
				}
				/* VALIDATION : END */

				if (validation) {
					if (_.isObject(this.prop_siteSettingArray.setting.fortuneWheelOptionColor)) {
						this.prop_siteSettingArray.setting.fortuneWheelOptionColor = JSON.stringify(this.prop_siteSettingArray.setting.fortuneWheelOptionColor);
					}

					if (this.$route.name == 'capture-setting-new') {
						settingObject.status = 1;
					}
					if (this.$route.name == 'capture-setting-clone') {
						settingObject.settingId = '';
					}
					this.$captureSettingAPI.post('save-and-publish-settings', settingObject).then((response) => {
						response = response.data;
						if (response.status) {

							/* ERROR MESSAGE FOR WRONG IMAGE FORMAT: START */
							if (response.ImageUploadError) {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.ImageUploadError.error,
									},
								});
								return false;
							}
							/* ERROR MESSAGE FOR WRONG IMAGE FORMAT: END */

							/* FORTUNE NEW OPTION UPDATE HERE WHEN SAVE ALL DATA : START*/
							if(!_.isEmpty(settingObject.fortuneWheelOptions) && settingObject.subGoal == 7) {
								let fortuneOptionObjectValues = JSON.parse(settingObject.fortuneWheelOptions);
								let responseIdsArray = response.fortuneOptionIds.fortune_option_ids.split(',');
								fortuneOptionObjectValues.fortuneOptionId = responseIdsArray;
								settingObject.fortuneWheelOptions = JSON.stringify(fortuneOptionObjectValues);
							}
							/* FORTUNE NEW OPTION UPDATE HERE WHEN SAVE ALL DATA : END*/
							
							if (this.$route.name == 'capture-setting-new') {
								this.$router.push('/manage-site');
							}
							if (this.$route.name == 'capture-setting-clone') {
								this.$router.push({ name: 'capture-setting', params: { setting_id: response.setting_id } })
							}
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'success',
									text: response.message,
								},
							});
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'danger',
									text: response.message,
								},
							});
						}
						this.isFetchingSiteSettingArray = false;
					});
				}
			},

			redirectToManageSite() {
				if (this.$route.name == 'capture-setting-new') {
					this.$swal({
						title: 'Are you sure to cancel?',
						text: "Capture settings will discard permanently.",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Yes, revert it!',
						customClass: {
							confirmButton: 'btn btn-primary',
							cancelButton: 'btn btn-outline-danger ml-1',
						},
						buttonsStyling: false,

					}).then((result) => {
						if (result.value) {
							this.$manageCaptureAPI.post('delete-site-setting', {
								'auth_id': this.$root.authUserObject.id ?? '',
								'site_id': this.$root.appSiteObject.siteId ?? '',
								'setting_id': this.$route.params.setting_id ?? '',
							}).then((response) => {
								response = response.data;

								if (response.status) {
									this.$router.push('/add-new-capture');
								}
							})
							this.$swal({
								title: 'Discarded!',
								text: "Your capture settings has been discarded.",
								icon: 'success',
								showConfirmButton: false,
								timer: 1500,
							});
						} else {
							this.$swal("Cancelled", "Your capture settings is safe :)", "error");
							return false;
						}
					})
				} else {
					this.$router.push('/manage-site');
				}
			},
			/* EVERY CHANGE TIME UPDATE ACTIVE TAB IN MAIN LABEL */
			activeTabFind(activeTabname, activeTabAvtar) {
				this.activeTab = activeTabname;
				this.activeAvtar = activeTabAvtar;
			},

			/* DYNAMIC TOAST ERROR MESSAGES */
			showToast(variant, message) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: message,
						icon: 'AlertOctagonIcon',
						variant,
					},
				})
			},

			fortueWheelOptionModel() {
                this.fortuneWheelOptions = {};
                this.$captureSettingAPI.post('fortune-wheel-options', {
                    'site_id': this.$root.appSiteObject.siteId ?? '',
                    'setting_id': this.$route.params.setting_id ?? '',
                }).then((response) => {
                    this.fortuneWheelOptionsObjectCheck = response.data;
                });
            },
		},
		setup(props) {
			const perfectScrollbarSettings = {
				maxScrollbarLength: 60,
				wheelPropagation: false,
			}
			const { settingMenuIcons } = captureIcons();
			return {
				perfectScrollbarSettings,
				settingMenuIcons
			}
		},
		watch: {
			prop_siteSettingArray(newVal, oldVal) {
				this.isFetchingSiteSettingArray = false;
				return newVal;
			}
		},
		created() {
			EventBus.$on('clickSaveAndPublishEvent', (data) => {
				this.saveAndPublishSite();
			});
		}
	}
</script>
<style lang="scss">
	@media (min-width: 768px) {
		.todo-application .content-area-wrapper .sidebar .todo-sidebar {
			width: 430px !important;
		}
	}

	.nav-tabs .nav-item {
		padding-bottom: 0.5rem;
		/*  UI RELATED CHANGES */
		width: 71px !important;	
	}

	.nav-tabs {
		padding: 0;
		padding-top: 0.5rem;
		margin-left: 0;
		margin-right: 0;
		background: rgba(115, 103, 240, 0.12) !important;
	}

	div.tab-content {
		padding-left: 0;
		/* padding-top: 1rem; */
	}

	div.sticky-top {
		background-color: white;
	}

	.dark-layout .sticky-top {
		background-color: #283046;
	}
</style>