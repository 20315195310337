<template>
	<!-- RULES ADD/EDIT MODAL -->
	<b-modal id="modal-add-edit-rules" ref="modal-add-edit-rules" cancel-variant="outline-secondary" ok-title="Save" cancel-title="Close" :title="prop_selectedTargetingRule == 0 ? 'Add Rule' : 'Edit Rule'" size="lg" @show="fetchCaptureRules()" @hidden="removeRulesObject()" @ok="handleOk">
		<b-overlay :show="isSave" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
			<validation-observer ref="rulesForm">
				<b-form id="rules-add-edit-form" ref="rules-add-edit-form" @submit.stop.prevent="submitRulesFrom">

				<!-- RULES WHO SHOULD SEE -->
				<b-container class="py-2">
					<b-row class="justify-content-md-center">
						<b-col sm class="align-self-center"><span class="float-md-right">Show to people who match</span></b-col>
						<b-col sm>
							<Select2 name="rulewhoShouldSee" :options="rulewhoShouldSee" v-model="rulewhoShouldSeeSelected" :settings="{'dropdownParent': '#modal-add-edit-rules' }" />
						</b-col>
						<b-col sm class="align-self-center"><span>of the following conditions.</span></b-col>
					</b-row>
				</b-container>

				<!-- RULES ADD ROW CONTENT -->
				<b-overlay :show="isFetchingRulesArray" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
					<b-container fluid="md">
						<b-row class="mt-1 card-body modal-header" v-for="(rule, key) in rulesSettingsObject" :key="key">

							<!-- rule_conditions_segment -->
							<b-col sm :class="$isMobile() == true ? `mb-50` : 'rulecond'">
								<Select2 :name="`rule[segment][${rule.elementsID}]`" :options="ruleConditionsSegment" v-model="rule.rule_conditions_segment" @change="dynamicPlaceholder($event, rule)" :settings="{'dropdownParent': '#modal-add-edit-rules' }" />
							</b-col>

							<!-- rule_conditions_operand -->
							<b-col sm :class="$isMobile() == true ? `mb-50` : 'pl-0 rulecond'">
								<Select2 :name="`rule[operand][${rule.elementsID}]`" v-if="rule.rule_conditions_segment == 'device'" :options="deviceOprandOptions" v-model="rule.rule_conditions_operand" :settings="{'dropdownParent': '#modal-add-edit-rules' }" />
								<Select2 :name="`rule[operand][${rule.elementsID}]`" v-else-if="rule.rule_conditions_segment == 'interacted' || rule.rule_conditions_segment == 'closed'" :options="ruleConditionOprand" v-model="rule.rule_conditions_operand" :settings="{'dropdownParent': '#modal-add-edit-rules' }" />
								<Select2 :name="`rule[operand][${rule.elementsID}]`" v-else :options="otherOprandOptions" v-model="rule.rule_conditions_operand" :settings="{'dropdownParent': '#modal-add-edit-rules' }" />
							</b-col>

							<!-- rule_conditions_choices -->
							<b-col sm :class="$isMobile() == true ? `mb-50` : 'pl-0 rulecond'" v-show="rule.rule_conditions_segment != 'interacted'">
								<!-- if rule_conditions_segment is device then select2 will appear -->
								<Select2 :name="`rule[choices][${rule.elementsID}]`" :options="deviceValueOptions" v-model="rule.rule_conditions_choices" v-if="rule.rule_conditions_segment == 'device'" :settings="{'dropdownParent': '#modal-add-edit-rules' }" />

								<!-- if rule_conditions_segment is not device then only input type text will appear -->
								<div v-else v-for="(choiceValue, choiceIndex) in rule.rule_conditions_choices" :key="choiceIndex">
									<b-form-input :name="`rule[conditions_attributes][${rule.elementsID}][]`" v-if="choiceIndex==0" :placeholder="rule.placeholder" v-model="rule.rule_conditions_choices[choiceIndex]" />
									<div v-else>
										<div class="divider divider-vertical"><span class="divider-text">Or</span></div>
										<b-input-group class="mt-1">
											<b-form-input :name="`rule[conditions_attributes][${rule.elementsID}][]`" :placeholder="rule.placeholder" v-model="rule.rule_conditions_choices[choiceIndex]" />
											<b-input-group-append>
												<b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="danger" class="btn-icon" @click="removeSubUrl(key, choiceIndex)">
													<feather-icon icon="Trash2Icon" />
												</b-button>
											</b-input-group-append>
										</b-input-group>
									</div>
									<div class="divider divider-vertical" v-if="choiceIndex == Object.keys(rule.rule_conditions_choices).length - 1 && (rule.rule_conditions_segment == 'url' || rule.rule_conditions_segment == 'urlPath')">
										<span class="divider-text">
											<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon" @click="addMoreUrl(key)">
												<feather-icon icon="PlusIcon" />
											</b-button>
										</span>
									</div>
								</div>
							</b-col>

							<!-- TRASH BUTTON -->
							<b-col sm cols="12" md="auto" :class="$isMobile() == false ? 'pl-0 ipadcond' : ''">
								<div>
									<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon float-sm-right" @click="deleteRowFunction(key)">
										<feather-icon icon="Trash2Icon" />
									</b-button>
								</div>
							</b-col>

						</b-row>
					</b-container>
				</b-overlay>

				<!-- ADD MORE BUTTON -->
				<div class="mt-1">
					<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addRowFunction()">
						<feather-icon icon="PlusIcon" class="mr-50" />
						<span class="align-middle">Add more</span>
					</b-button>
				</div>

				<!-- nameWhoShouldSee -->
				<b-container class="py-2">
					<b-row class="justify-content-md-center">
						<b-col md="8" sm="12">
							<validation-provider #default="{ errors }" name="nameWhoShouldSee" rules="required" :custom-messages="{ required: 'Please enter Rule name' }">
								<b-form-input name="nameWhoShouldSee" id="nameWhoShouldSee" placeholder="name" v-model="editedTagetObject.nameWhoShouldSee" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>
					</b-row>
				</b-container>
				</b-form>
			</validation-observer>
		</b-overlay>
	</b-modal>
</template>

<script>
	import {
		BFormInput, BInputGroup, BModal, VBModal, BButton, BForm, BFormGroup, BCardText, BCol, BAlert, BRow, BInputGroupAppend, BCard, BContainer, BOverlay
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import captureOptions from '@/libs/capture-Options'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import { required } from '@validations'

	export default {
		components: {
			BFormInput, BInputGroup, BModal, VBModal, BButton, BForm, BFormGroup, BCardText, BCol, BAlert, BRow, BInputGroupAppend, BCard, BContainer, BOverlay,
			ValidationProvider, ValidationObserver, captureOptions
		},
		directives: {
			Ripple,
		},
		props: {
			prop_selectedTargetingRule: [String, Number],
			prop_targetNamesObject: {
				type: Array,
				default: () => [],
			},
		},
		setup() {
			const { rulewhoShouldSee, ruleConditionsSegment, deviceOprandOptions, otherOprandOptions, deviceValueOptions, ruleConditionOprand } = captureOptions();
			return {
				rulewhoShouldSee, ruleConditionsSegment, deviceOprandOptions, otherOprandOptions, deviceValueOptions, ruleConditionOprand
			}
		},
		data() {
			return {
				isFetchingRulesArray: false,
				rulesSettingsObject: [],
				editedTagetObject: {},
				cloneCounter: 0,
				isSave : false
			}
		},
		methods: {
			/* FETCH ALL RULE CONDITIONS FROM DATABASE */
			fetchCaptureRules() {
				if (this.prop_selectedTargetingRule != 0) { /* EDIT RULE */
					this.isFetchingRulesArray = true;
					this.editedTagetObject = _.find(_.values(this.prop_targetNamesObject), { targetingId: this.prop_selectedTargetingRule }); /* FETCH SELECTED TARGETING ID OBJECT AND SET TO THIS OBJECT */
					if(this.editedTagetObject  != undefined){
						this.$captureSettingAPI.post('fetch-capture-rules', {
							'targetingId': this.editedTagetObject.targetingId ?? '',
						}).then((response) => {
							response = response.data
							if (response.status) {
								this.rulesSettingsObject = _.map(response.target_rules, (item) => {
									item.rule_conditions_choices = item.rule_conditions_choices.split(',');
									return item;
								})
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
							this.isFetchingRulesArray = false;
						});
					}
				} else { /* ADD RULE */
					this.editedTagetObject = {}
					this.isFetchingRulesArray = false;
				}
			},

			/* ON HIDE MODEL OBJECT SHOULD BE BLANK */
			removeRulesObject() {
				this.cloneCounter = 0;
				this.rulesSettingsObject = [];
				this.editedTagetObject = {};
			},

			/* ADD ROW AUTOMATICALLY AND APPEND TO LAST FUNCTION */
			addRowFunction() {
				var my_object = {
					elementsID: this.cloneCounter,
					targetingId: this.editedTagetObject.targetingId,
					rule_conditions_segment: "device",
					rule_conditions_operand: "is",
					rule_conditions_choices: "mobile",
					createdDate: "",
					placeholder: ""
				}
				this.rulesSettingsObject.push(my_object)
				this.cloneCounter++;
			},

			/* DELETE ROW FROM RULES LIST FUNCTION */
			deleteRowFunction(key) {
				this.$delete(this.rulesSettingsObject, key);
			},

			/* APPLY DYNAMIC PLACEHOLDER TO FIELDS */
			dynamicPlaceholder(event, rule) {
				rule.rule_conditions_choices = [""];
				if (event == 'device') {
					rule.rule_conditions_choices = 'mobile';
				} else if (event == 'url') {
					rule.placeholder = '/specific_url';
				} else if (event == 'urlQuery') {
					rule.placeholder = 'query=value';
				} else if (event == 'urlPath') {
					rule.placeholder = '/specific_path';
				} else if (event == 'interacted') {
					rule.rule_conditions_operand = 'days';
				} else {
					rule.placeholder = '';
				}
			},

			/* ADD ROW AUTOMATICALLY AND APPEND TO LAST FUNCTION */
			addMoreUrl(key) {
				var my_object = "";
				this.rulesSettingsObject[key].placeholder = this.rulesSettingsObject[key].rule_conditions_segment == 'urlPath' ? '/specific_path' : '/specific_url';
				this.rulesSettingsObject[key].rule_conditions_choices.push(my_object);
			},

			/* ADD ROW AUTOMATICALLY AND APPEND TO LAST FUNCTION */
			removeSubUrl(key, subkey) {
				this.$delete(this.rulesSettingsObject[key].rule_conditions_choices, subkey);
			},

			/* PREVENT DEFAULT ON SAVE MODAL AND DO NOT CLOSE MODAL WITHOUT VALIDATION */
			handleOk(bvModalEvent) {
				// Prevent modal from closing
				bvModalEvent.preventDefault()
				// Trigger submit handler
				this.submitRulesFrom()
			},

			/* SAVE RULE TO DATABASE */
			submitRulesFrom() {
				this.$refs.rulesForm.validate().then(success => {
					if (success) {
						if (this.rulesSettingsObject.length > 0) {
							this.isSave = true;
							let formData = new FormData(document.getElementById('rules-add-edit-form'));
							const data = {};
							for (let [key, val] of formData.entries()) { /* NEED TO CONVERT IT BEFORE USING NOT WITH XMLHTTPREQUEST */
								Object.assign(data, { [key]: val })
							}
							data.targetingId = this.editedTagetObject.targetingId ?? '';
							data.site_id = this.$root.appSiteObject.siteId ?? '';
							data.setting_id = this.$route.params.setting_id ?? '';

							this.$captureSettingAPI.post('save-targeting-rule', data).then((response) => {
								if (response) {
									response = response.data;
									this.isSave = false;
									this.$emit("newOption", response);
									this.$nextTick(() => {
										this.$bvModal.hide('modal-prevent-closing')
									})
									this.$refs['modal-add-edit-rules'].hide();
									this.$toast({
										component: ToastificationContent,
										props: {
											title: 'Notification',
											icon: 'BellIcon',
											variant: 'success',
											text: response.action == 'update' ? 'Rules has been updated' : 'Rules has been added',
										},
									});
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: 'Notification',
											icon: 'BellIcon',
											variant: 'danger',
											text: response.message,
										},
									});
								}
							});
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'danger',
									text: 'Please select atleast one rule',
								},
							});
						}
					}
				})
			},
		},
		computed: {

			/* IF VALUE NOT FOUND THEN AUTO SELECTED 'ALL' TO OPTIONS */
			rulewhoShouldSeeSelected: {
				get() {
					if (_.get(this.editedTagetObject, 'rulewhoShouldSee')) {
						return this.editedTagetObject.rulewhoShouldSee;
					} else {
						return 'all';
					}
				},
				set(newValue) {
					this.editedTagetObject.rulewhoShouldSee = newValue;
				}
			},
		},
		watch: {
			/* FOR GET NEWEST VALUE */
			prop_targetNamesObject: (newVal) => {
				return newVal;
			},

			prop_selectedTargetingRule: {
				handler(newVal) {
					if(this.$route.fullPath == "/manage-site"){
						this.fetchCaptureRules();
					}
					return newVal;
				},
			},
		}
	}
</script>
<style lang="scss">
	.rules-row {
		padding: 1rem 0 1rem 0;
	}

	@media only screen and (max-width: 991px) and (min-width: 768px) {
		/* UI RELATED CHANGES */
			.rulecond {
				min-width: 50%;
				max-width: 50%;
				padding-top: 1rem;
				padding-left: 0;
			}

			.ipadcond {
				padding-top: 1rem;
			}
		}
</style>