<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-form-group label="Keyword">
                    <validation-provider name="Keyword" rules="required">
                        <Select2 v-model="selectedValues.setting.textapp_keyword" :options="this.textappKeywords" placeholder="Select Keyword" />
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row> 

        <b-row v-if="selectedValues.setting.afterSubmit">
            <b-col md="12">
                <b-form-group label="After visitor submits their email ..." label-for="afterSubmit">
                    <validation-provider #default="{ errors }" name="a fterSubmit" rules="required">
                        <Select2 v-model="selectedValues.setting.afterSubmit" :state="errors.length > 0 ? false:null" :options="afterSubmitOptions" name="afterSubmit" />
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-form-group>

                    <b-form-input id="defaultMessage" name="defaultMessage" value="Thank you for signing up." placeholder="Thank you for signing up." v-if="selectedValuesSetting.afterSubmit==1" v-model="selectedValuesSetting.defaultMessage" :readonly="true" />

                    <b-form-input id="customMessage" name="customMessage" v-if="selectedValuesSetting.afterSubmit==2" v-model="selectedValuesSetting.customMessage" />

                    <b-form-input id="visitorURL" name="visitorURL" v-if="selectedValuesSetting.afterSubmit==3" v-model="selectedValuesSetting.visitorURL" />

                </b-form-group>
            </b-col>
        </b-row>
        <br>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Ripple from 'vue-ripple-directive'

    export default {
        directives: {
            Ripple
        },
        components: {
            BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup,
            ValidationProvider, ValidationObserver,
        },
        name: 'SubscribeToTextAppSetting',
        props: {
			prop_siteSettingArray: {
				type: Object,
				default: () => {},
			}
		},
        data() {
            return { 
                textappKeywords : [], 
                afterSubmitOptions: [
                    { text: 'Show default message', id: 1 },
                    { text: 'Show a custom message', id: 2 },
                    { text: 'Redirect the visitor to a url', id: 3 },
                ],
                defaultMessage: 'Thank you for signing up.',
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (_.get(this.prop_siteSettingArray, 'setting') && _.get(this.prop_siteSettingArray, 'textapp_keywords')) {
                    for (const Keywords of this.prop_siteSettingArray.textapp_keywords) {
                        this.textappKeywords.push({
                            'id': Keywords.keyword_id+":"+Keywords.user_id,
                            'text': Keywords.keyword_name,
                        });
                    }
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                } 
            },

            selectedValuesSetting() {
                this.selectedValues.setting.defaultMessage = this.defaultMessage;
                return this.selectedValues.setting;
            },
        }, 
    }
</script>