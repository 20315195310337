<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-form-group label="Headline">
                    <b-form-input v-model="selectedValues.captureBarSay" name="captureBarSay" placeholder="What you want to announce ?" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol,BFormInput, BFormGroup, } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        directives: {
            Ripple
        },
        components: {
            BRow, BCol, BFormInput, BFormGroup, 
        },
        name: 'AnnouncementSetting',
        props: {
			prop_siteSettingArray: {
				type: Object,
				default: () => {},
			}
		},
        data() {
            return {  
            }
        },  
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray.setting;
                } else {
                    return [];
                }
            },
        },
    }
</script>