<template>
	<!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
	<div style="height: inherit">
		<div class="body-content-overlay" :class="{ show: mqShallShowLeftSidebar }" @click="mqShallShowLeftSidebar = false" />
		<div class="d-flex flex-column h-100">
			<!-- App Searchbar Header -->
			<div class="app-fixed-search d-flex align-items-center">
				<!-- Toggler for mobile -->
				<div class="sidebar-toggle d-block d-lg-none ml-1">
					<feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
				</div>

				<!-- Searchbar -->
				<div class="d-flex align-content-center justify-content-between w-100">
					<b-input-group class="input-group-merge d-flex align-content-center justify-content-between">
						<b-input-group-prepend is-text>
							<feather-icon icon="SettingsIcon" size="21" />
							<h5 class="font-weight-bolder ml-50 mb-0 p-25">
								Capture Settings
							</h5>
						</b-input-group-prepend>
						<!-- MOBILE AND PHONE VIEW MENU DETAIL COMPONENT AND DETAIL : START -->
						<b-input-group-prepend is-text class="py-0">
							<font-awesome-icon icon="fa-sharp fa-solid fa-desktop" class="fa-2x cursor-pointer" :class="{ 'text-primary': previewType == 1 }" @click="changeDesktopPreview" />
							<font-awesome-icon icon="fa-sharp fa-solid fa-mobile" class="ml-1 fa-2x cursor-pointer" :class="{ 'text-primary': previewType == 2 }" @click="changeMobilePreview" />
						</b-input-group-prepend>
						<!-- MOBILE AND PHONE VIEW MENU DETAIL COMPONENT AND DETAIL : END -->
					</b-input-group>
				</div>
			</div>

			<div class="w-100 h-100" :class="{ 'd-none': previewType != 1 }" :style="desktopDivStyleObjects">
				<iframe id="desktop" allowtransparency="true" class="iframe top align-self-stretch" style="width:100%;border:none;height:100%;" scrolling="auto"></iframe>
			</div>

			<div class="iphone" :class="{ 'd-none': previewType != 2 }">
				<div class="smartphone">
					<div class="content w-100 h-100" :style="mobileDivStyleObjects">
						<iframe id="mobile" allowtransparency="true" class="iframe align-self-stretch" style="width:100%;border:none;height:100%"></iframe>
					</div>
				</div>
			</div>

			<!-- Todo List -->

		</div>
		<!-- Sidebar -->
		<portal to="content-renderer-sidebar-left" v-if="siteSettingArray">
			<filters-left-sidebar :class="{ show: mqShallShowLeftSidebar }" :prop_siteSettingArray="siteSettingArray" />
		</portal>
	</div>
</template>

<script>
	import { BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar, BImg, BOverlay, BRow, BCol } from "bootstrap-vue";
	import VuePerfectScrollbar from "vue-perfect-scrollbar";
	import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
	import FiltersLeftSidebar from "./FiltersLeftSidebar.vue";
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar, BImg, BOverlay, BRow, BCol,
			// App SFC
			VuePerfectScrollbar,
			FiltersLeftSidebar,
		},
		data() {
			return {
				filtersObject: {},
				siteSettingArray: {},
				menuHidden: this.$store.state.appConfig.layout.menu.hidden,
				previewType: 1, /* FOR DEFAULT DESKTOP */
				capturePreviewHTML: null,
			};
		},
		mounted() {
			this.fetchCaptureSettings();
		},
		computed: {
			siteImagePath() {
				if (_.isEmpty(this.$root.appSiteObject.siteImage)) {
					return '';
				}
				return this.$root.appBaseURL + '/' + this.$root.getUploadFilePath(`website/${this.$root.appSiteObject.siteImage}?${Date.now()}`);
			},
			mobileDivStyleObjects() {
				return {
					'background-image': `url('${this.siteImagePath}')`,
					'background-repeat': 'no-repeat',
					'background': `transparent url('${this.siteImagePath}') no-repeat scroll 0px 0px;`,
					'background-size': '100% 100%',
					'background-position': 'center center',
				}
			},
			desktopDivStyleObjects() {
				return {
					'background-image': `url('${this.siteImagePath}')`,
					'background-repeat': 'no-repeat',
					'background-size': '100% 100%',
					'background-position': 'center center',
				}
			}
		},
		methods: {
			selectedFilterEvent(dataObject) {
				/* CALLING FROM LEFT SIDE FILTER */
				this.mqShallShowLeftSidebar = false; /* CLOSE LEFT SITE ON MOBILE VIEW */
				this.filtersObject = dataObject;
			},
			fetchCaptureSettings() {
				this.$captureSettingAPI.post('fetch-capture-setting', {
					'site_id': this.$root.appSiteObject.siteId ?? '',
					'setting_id': this.$route.params.setting_id ?? '',
				}).then((response) => {
					response = response.data;

					if (response.status) {
						/* IF SETTING NOT FOUND THEN REDIRECT TO 404 */
						if (!response.setting) {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'danger',
									text: 'Capture not found',
								},
							});
							this.$router.push('/manage-site');

						}
						this.siteSettingArray = {}
						this.siteSettingArray = response;
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			},

			/**
			 * FOR DESKTOP PREVIEW
			 */
			changeDesktopPreview() {
				this.previewType = 1;
				let desktopPreview = document.getElementById('desktop').contentWindow.document;
				desktopPreview.open();
				desktopPreview.write(this.capturePreviewHTML);
				desktopPreview.close();
			},

			/**
			 * FOR MOBILE PREVIEW
			 */
			changeMobilePreview() {
				this.previewType = 2;
				let mobilePreview = document.getElementById('mobile').contentWindow.document;
				mobilePreview.open();
				mobilePreview.write(this.capturePreviewHTML);
				mobilePreview.close();
			},

			/**
			 * FOR CAPTURE PREVIEW ON IFRAME WITH MOBILE AND DESKTOP
			 */

			capturePreview() {
				
				if (this.siteSettingArray.setting.subGoal == 7) {
					if (_.isObject(this.siteSettingArray.setting.fortuneWheelOptionColor)) {
						this.siteSettingArray.setting['fortuneWheelOptionColorJson'] = JSON.stringify(this.siteSettingArray.setting.fortuneWheelOptionColor);
					} else {
						this.siteSettingArray.setting['fortuneWheelOptionColorJson'] = this.siteSettingArray.setting.fortuneWheelOptionColor;
					}
				}

				this.siteSettingArray.setting['customframe'] = 'customframe';

				this.$captureSettingAPI.post('capture-preview-with-settings', this.siteSettingArray.setting).then((response) => {
					this.capturePreviewHTML = response.data;

					if (this.previewType == 1) {
						this.changeDesktopPreview();
					} else {
						this.changeMobilePreview();
					}

					/* FOR STYLE TYPE CSS : START */
					let mobileIfrmaeClass = "Bar";
					if (this.siteSettingArray.setting.styleType == 1) {
						mobileIfrmaeClass = "Bar";
					} else if (this.siteSettingArray.setting.styleType == 2) {
						mobileIfrmaeClass = "Modal";
					} else if (this.siteSettingArray.setting.styleType == 3) {
						mobileIfrmaeClass = "Slider";
					} else if (this.siteSettingArray.setting.styleType == 4) {
						mobileIfrmaeClass = "Takeover";
					} else if (this.siteSettingArray.setting.styleType == 6) {
						mobileIfrmaeClass = "SideModel";
					} else if (this.siteSettingArray.setting.styleType == 7) {
						mobileIfrmaeClass = "SlideBar";
					}
					/* FOR STYLE TYPE CSS : END */

					this.appendScript(this.$root.appBaseURL + '/assets/js/frame.js');
					if (this.siteSettingArray.setting.styleType != 2) {
						this.appendStyle(this.$root.appBaseURL + '/assets/css/capture-' + mobileIfrmaeClass.toLowerCase() + '-iframe.css');
					}

				});
			},

			/**
			 * ADD JS SCRIPT ON PREVIEW IFRAME
			 */
			appendScript(filepath) {

				let scriptTag = document.createElement('script');
				scriptTag.setAttribute("type", "text/javascript");
				scriptTag.setAttribute("src", filepath);

				let mobilePreview = document.getElementById('mobile');
				let mobileIframeDocument = mobilePreview.contentDocument || mobilePreview.contentWindow.document;
				if (_.isEmpty(mobileIframeDocument.querySelector(`script[src="${filepath}"]`))) {
					mobileIframeDocument.querySelector('head').appendChild(scriptTag);
				}

				let desktopPreview = document.getElementById('desktop');
				let desktopIframeDocument = desktopPreview.contentDocument || desktopPreview.contentWindow.document;
				if (_.isEmpty(mobileIframeDocument.querySelector(`script[src="${filepath}"]`))) {
					desktopIframeDocument.querySelector('head').appendChild(scriptTag);
				}

			},

			/**
			 * ADD CSS STYLE ON PREVIEW IFRAME
			 */
			appendStyle(filepath) {

				var linkTag = document.createElement('link');
				linkTag.setAttribute("type", "text/css");
				linkTag.setAttribute("rel", "Stylesheet");
				linkTag.setAttribute("href", filepath);

				let mobilePreview = document.getElementById('mobile');
				let mobileIframeDocument = mobilePreview.contentDocument || mobilePreview.contentWindow.document;
				if (_.isEmpty(mobileIframeDocument.querySelector(`link[href="${filepath}"]`))) {
					mobileIframeDocument.querySelector('head').appendChild(linkTag);
				}

				let desktopPreview = document.getElementById('desktop');
				let desktopIframeDocument = desktopPreview.contentDocument || desktopPreview.contentWindow.document;
				if (_.isEmpty(mobileIframeDocument.querySelector(`link[href="${filepath}"]`))) {
					desktopIframeDocument.querySelector('head').appendChild(linkTag);
				}
			}
		},
		setup() {
			const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

			return {
				mqShallShowLeftSidebar /* LEFT SIDEBAR RESPONSIVE */,
			};
		},
		watch: {
			'siteSettingArray.setting': {
				handler: function (after, before) {
					this.capturePreview();
				},
				deep: true
			},
		},
		created() {
			if (this.$isMobile() == false) { /* IF DEVICE IS DESKTOP THEN ONLY HIDE MENU */
				this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
			}
			// this.fetchCaptureSettings();

		},
		destroyed() {
			// Restore the state value of `appConfig` when page/SFC is destroyed
			this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
		},
	};
</script>
<style lang="scss">
	@media (max-width: 575.98px) {
		html [data-col="1-column"] .header-navbar.floating-nav {
			width: calc(100vw - (100vw - 100%) - calc(2rem + 0.4rem)) !important;
		}
	}

	@import "~@core/scss/base/pages/app-todo.scss";

	.iphone {
		width: 270px;
		margin: auto;
		height: 97%;
		float: none;
		position: relative;
	}

	.smartphone {
		position: relative;
		width: 382px;
		height: 93%;
		margin: auto;
		border: 16px white solid;
		box-shadow: 5px 10px #00000017;
		border-top-width: 60px;
		border-bottom-width: 60px;
		border-radius: 36px;
		margin-top: 10px;

		&:before {
			content: '';
			display: block;
			width: 60px;
			height: 5px;
			position: absolute;
			top: -30px;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #333;
			border-radius: 10px;
		}

		&:after {
			content: '';
			display: block;
			width: 35px;
			height: 35px;
			position: absolute;
			left: 50%;
			bottom: -65px;
			transform: translate(-50%, -50%);
			background: #333;
			border-radius: 50%;
		}

		.content {
			width: 351px;
			height: 100%;
		}
	}
</style>