<template>
    <!-- FORTUNE WHEEL OPTIONS MODAL  -->
    <b-modal id="fortune-wheel-options-modal" ref="myFortuneModal" title="Fortune Wheel Options"
        modal-class="modal-primary" size="xl" cancel-variant="outline-secondary" ok-title="Confirm" cancel-title="Close"
        @ok="handleOk" @hidden="hiddeModalObject()">
        <hr>
        <b-container fluid>
            <b-row class="ml-2 mb-1 text-center" v-if="isMobile">
                <b-col md="1">
                    <h4>Status</h4>
                </b-col>
                <b-col md="4">
                    <h4>Title</h4>
                </b-col>
                <b-col md="2">
                    <h4>Coupon code</h4>
                </b-col>
                <b-col md="2">
                    <h4>Valid till</h4>
                </b-col>
                <b-col md="2">
                    <h4>Winning interval</h4>
                </b-col>
                <b-col md="1">
                    <h4></h4>
                </b-col>
            </b-row>
            <hr>
            <b-form ref="form" @submit.stop.prevent="saveRecord">
                <b-row class="ml-2 p-1 formBorder" v-for="(item, key) in fortuneWheelOptionsItems" :key="key">
                    <b-col md="1">
                        <b-form-group>
                            <label v-if="isDesktop">Status</label>
                            <b-form-checkbox :id="`status-${key}`" v-model="fortuneWheelOptionsItems[key].status"
                                value="1" unchecked-value="0" :stacked="fortuneWheelOptionsItems[key].status"
                                @change="statusCheck(key)"></b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <hr>
                    <b-col md="4">
                        <b-form-group>
                            <label v-if="isDesktop">Title</label>
                            <b-form-input v-model="item.title" class="form-control" autocomplete="off"
                                placeholder="Enter Title" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group>
                            <label v-if="isDesktop">Coupon code</label>
                            <b-form-input v-model="fortuneWheelOptionsItems[key].couponCode" class="form-control"
                                autocomplete="off" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group>
                            <label v-if="isDesktop">Valid till</label>
                            <flat-pickr v-model="fortuneWheelOptionsItems[key].validTill" class="form-control"
                                :disabled="key <= 5" placeholder="Select date" :config="{ minDate: new Date() }" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group v-if="fortuneWheelOptionsItems[key].isHideWinningInterval">
                            <label v-if="isDesktop">Winning interval</label>
                            <b-form-input type="number" v-model="fortuneWheelOptionsItems[key].winningInterval"
                                class="form-control" autocomplete="off" min="2" placeholder="Enter Winning Interval"
                                @input="winningInterval($event, key)" />
                        </b-form-group>
                    </b-col>
                    <b-col md="1">
                        <b-form-group>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon"
                                @click="deleteRow(key, fortuneWheelOptionsItems[key].fortune_option_id)">
                                <feather-icon icon="Trash2Icon" />
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="11">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addNewRow()">
                            <feather-icon icon="PlusIcon" class="mr-50" />
                            <span class="align-middle">Add More</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-container>

        <hr>
        <b-card-text class="mt-1">
            <h5 class="font-weight-bolder">Note :</h5>
            <p>1) Minimum 6 options are compulsory.</p>
            <p>2) Valid till date will be available from 7th option onward.</p>
        </b-card-text>
    </b-modal>
</template>

<script>
import { BModal, BCardText, VBModal, BTable, BFormCheckbox, BButton, BCard, BAvatar, BBadge, BFormGroup, BFormInput, BOverlay, BRow, BCol, BFormCheckboxGroup, BContainer, BForm } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    model: {

    },
    components: {
        BModal, BCardText, ValidationProvider, ValidationObserver, BTable, BFormCheckbox, BButton, BCard, BAvatar, BBadge, BFormGroup, BFormInput, BOverlay, BRow, BCol, BFormCheckboxGroup, flatPickr, BForm, BContainer,
    },
    props: {
        prop_fortuneWheelOptions: {
            type: Object,
            default: () => { },
        },
        prop_siteSettingArray: {
            type: Object,
            default: () => { },
        }
    },
    data() {
        return {
            fields: ['status', 'title', 'coupon_code', 'valid_till', 'winning_interval', 'fortune_option_id'],
            defaultTitle: [
                { title: 'No luck today', status: '1', isHideWinningInterval: true },
                { title: 'Nothing', status: '1', isHideWinningInterval: true },
                { title: 'Nope', status: '1', isHideWinningInterval: true },
                { title: 'Sorry!', status: '1', isHideWinningInterval: true },
                { title: 'Next Time', status: '1', isHideWinningInterval: true },
                { title: 'Almost!', status: '1', isHideWinningInterval: true },
            ],
            fortuneWheelOptionsItems: [],
            date: null,
            dateDefault: null,
            isMobile: this.$isMobile() == false ? true : false,
            isDesktop: this.$isMobile() == false ? false : true,
            fortuneWheelOptionDeletedArray: [],
        }
    },
    created() {
        this.fortuneWheelOptionsArrayFunction();
        this.storePropValueFortuneWheelOptions();
    },
    methods: {
        /* SAVE RECORD */
        saveRecord() {
            /* ALL TITLE ARE REQUIRED */
            if (this.titleRowEmptyCheck) {
                this.alertToast('danger', 'Please enter title first');
                return false;
            }

            /* WINNINGINTERVAL HIDE/SHOW */
            let winningInterval = this.mapFortuneWheelOptions('winningInterval');
            let inputWinningIntervalNumber = '';
            let getWinningIntervalNumber = _.map(winningInterval, function (value) {
                if (_.isEmpty(value)) {
                    return null;
                } else {
                    inputWinningIntervalNumber = value
                    return value;
                }
            });

            /* WINNINGINTERVALNUMBER MINMUM 2 NUMBER REQUIRED */
            if (!_.isEmpty(inputWinningIntervalNumber)) {
                if (inputWinningIntervalNumber <= 1) {
                    this.alertToast('danger', 'Please set minimun Winning Interval 2 or above.');
                    return false;
                }
            }

            /* COUPONCODECHECK CHECK WINNIG INTERNAL VALUE EMPTY OR NOT */
            let couponCodeCheck = this.mapFortuneWheelOptions('couponCode');
            let checkWinningInterval = _.map(winningInterval, (item, itemKey) => {
                if (!_.isEmpty(item)) {
                    if (_.isEmpty(couponCodeCheck[itemKey])) {
                        this.alertToast('danger', 'Please enter coupon code to Winning Interval rows');
                        bvModalEvent.preventDefault()
                        return false;
                    }
                }
            });

            /* STORE VALUE IN FORTUNE WHEEL OPTION */
            this.storePropValueFortuneWheelOptions();

            /* 
            * MAIN ARRAY STORE ALL FORTUNE WHEEL OPTION DELETED ARRAY 
            */
            this.prop_siteSettingArray.setting.fortuneWheelOptionDeleted = JSON.stringify(this.fortuneWheelOptionDeletedArray);

            EventBus.$emit('clickSaveAndPublishEvent', true); /* FOR SAVE AND PUBLISH CAPTURE */

            /* HIDE MODEL */
            this.$nextTick(() => {
                this.$bvModal.hide('fortune-wheel-options-modal')
            })
            this.$refs['myFortuneModal'].hide();
        },

        /* GET FORTUNEWHEELOPTIONS FORM PROPS AND STORE OHTER ARRAY(fortuneWheelOptionsItems). IF VALUE NOT FOUND THEN STORE STATIC VALUE IN ARRAY(fortuneWheelOptionsItems) */
        fortuneWheelOptionsArrayFunction() {
            this.fortuneWheelOptionsItems = [];
            let newfortuneWheelArray = [];
            let winningStatus = [];
            /* CHECKING IF ALL WINNING INTERVAL IS NULL OR NOT */
            winningStatus = _.map(this.prop_fortuneWheelOptions.fortuneWheelOptions, (item, itemKey) => {
                    if (item.winningInterval != null) 
                        return 1;
                    else
                        return 0;
                });
            if (this.totalFortuneWheelOptions >= 6) {
                _.map(this.prop_fortuneWheelOptions.fortuneWheelOptions, (item, itemKey) => {
                    let isHideWinningInterval = false;

                    if (winningStatus.includes(1)) { /* IF WINNING INTERVAL FOUNDS */
                        isHideWinningInterval = false;
                    } else { 
                        isHideWinningInterval = true;
                    }

                    if (!_.isEmpty(item.winningInterval)) { /* SHOWING ELEMENENT WITH WINNING VALUE */
                        isHideWinningInterval = true;
                    }

                    newfortuneWheelArray.push({
                        couponCode: item.couponCode,
                        created_at: item.created_at,
                        fortune_option_id: item.fortune_option_id,
                        nextWinningNumber: item.nextWinningNumber,
                        settingId: item.settingId,
                        siteId: item.siteId,
                        status: item.status,
                        title: item.title,
                        updated_at: item.updated_at,
                        validTill: item.validTill,
                        winningInterval: item.winningInterval,
                        isHideWinningInterval: isHideWinningInterval,
                    });
                });
                return this.$set(this, 'fortuneWheelOptionsItems', newfortuneWheelArray);
            } else {
                return this.$set(this, 'fortuneWheelOptionsItems', this.defaultTitle);
            }
        },

        storePropValueFortuneWheelOptions() {
            if (this.fortuneWheelOptionsItems.fortuneWheelOptions == null) {
                this.fortuneWheelStoreArray = [];
                this.fortuneWheelStoreArray.push({
                    fortuneOptionId: this.mapFortuneWheelOptions('fortune_option_id'),
                    status: this.mapFortuneWheelOptions('status'),
                    titles: this.mapFortuneWheelOptions('title'),
                    couponcodes: this.mapFortuneWheelOptions('couponCode'),
                    validtills: this.mapFortuneWheelOptions('validTill'),
                    winningInterval: this.mapFortuneWheelOptions('winningInterval')
                })
                let fortureWheelStoreObject = _.reduce(this.fortuneWheelStoreArray, function (obj, param) {
                    obj = param
                    return obj;
                }, {});

                if (_.isObject(fortureWheelStoreObject)) {
                    /* 
                    * MAIN OBJECT STORE ALL FORTUNE WHEEL OPTION 
                    */
                    this.prop_siteSettingArray.setting.fortuneWheelOptions = JSON.stringify(fortureWheelStoreObject);

                }
                this.prop_siteSettingArray.setting.fortuneWheelOptionDeleted = this.fortuneWheelOptionDeletedArray;
            }
        },

        hiddeModalObject() {
            this.storePropValueFortuneWheelOptions();
        },

        /* SUBMIT EVENT */
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.saveRecord()
        },

        /* ADD NEW ROW WHEN CLICK ADD MORE BUTTON */
        addNewRow() {
            if (!this.titleRowEmptyCheck) {
                let isWinningInterval = _.filter(this.fortuneWheelOptionsItems, (item) => {
                    if (!_.isEmpty(item.winningInterval)) {
                        return true
                    } else {
                        return false;
                    }
                });
                let isHideWinningInterval = false;
                if (_.isEmpty(isWinningInterval)) {
                    isHideWinningInterval = true;
                }
                this.fortuneWheelOptionsItems.push({ status: 1, isHideWinningInterval: isHideWinningInterval });
            } else {
                this.alertToast('danger', 'Please enter title first');
                return false;
            }
        },

        /* DELETE ROW WHEN CLICK DELETE BUTTON */
        deleteRow(key, fortuneOptionId) {
            let activeKey = this.mapFortuneWheelOptions('status');
            if (this.totalRowField > 6 && (this.totalActiveRowCount > 6 ||
                (this.totalActiveRowCount == 6 && activeKey[key] == 0))) {
                    this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {

                        /* REMOVE ROW */
                        this.fortuneWheelOptionsItems.splice(key, 1);

                        /* FOR FETCH ROW IF WINNING INTERVAL ADDED BY USER */
                        let isHideWinningInterval = false;
                        let isWinningInterval = _.filter(this.fortuneWheelOptionsItems, (item) => {
                            if (!_.isEmpty(item.winningInterval)) {
                                return true
                            } else {
                                return false;
                            }
                        });
                        if (_.isEmpty(isWinningInterval)) {
                            isHideWinningInterval = true;
                        }
                        /* FOR FETCH ROW IF WINNING INTERVAL ADDED BY USER */

                        /* FOR HIDE OTHER WINNING INTERVAL INPUT */
                        this.fortuneWheelOptionsItems = _.map(this.fortuneWheelOptionsItems, (item, itemKey) => {
                            if (isHideWinningInterval) {
                                item.isHideWinningInterval = true;
                            } else {
                                item.isHideWinningInterval = false;
                                if (!_.isEmpty(item.winningInterval)) {
                                    item.isHideWinningInterval = true;
                                }
                            }
                            return item;
                        });
                        /* FOR HIDE OTHER WINNING INTERVAL INPUT */
                    }
                    if (!_.isEmpty(fortuneOptionId)) {
                        this.fortuneWheelOptionDeletedArray.push(fortuneOptionId)
                    }
                })
            } else {
                this.alertToast('danger', 'Sorry! Minimum 6 options are compulsory!')
                return false;
            }
        },

        /* CHECK STATUS (STATUS ACTIVE AND DEACTIVE) */
        statusCheck(key) {
            if (this.totalActiveRowCount < 6 || this.totalRowField <= 6) {
                this.alertToast('danger', 'Sorry! Minimum 6 options are compulsory!')
                this.$set(this.fortuneWheelOptionsItems[key], 'status', 1);
                return false;
            }
        },

        /* CHECK WINNING INTERVAL (STATUS ACTIVE AND DEACTIVE) */
        winningInterval(data, key) {
            this.fortuneWheelOptionsItems = _.map(this.fortuneWheelOptionsItems, (item, itemKey) => {
                if (!_.isEmpty(data)) {
                    if (key == itemKey) {
                        item.isHideWinningInterval = true;
                    } else {
                        item.isHideWinningInterval = false;
                    }
                } else {
                    item.isHideWinningInterval = true;
                }
                return item;
            });
        },

        /* DYNAMIC ARRAY MAP */
        mapFortuneWheelOptions(column) {
            return _.map(this.fortuneWheelOptionsItems, column);
        },

        /* DYNAMIC ALERT TOAST MESSAGES */
        alertToast(variant = null, message) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Alert',
                    icon: 'BellIcon',
                    text: message,
                    variant,
                },
            })
        },
    },
    computed: {
        /* COUNT TOTAL ROW */
        totalRowField() {
            return _.size(this.fortuneWheelOptionsItems);
        },

        /* COUNT FORTUNEWHEELOPTIONS'S DATABASE */
        totalFortuneWheelOptions() {
            return _.size(this.prop_fortuneWheelOptions.fortuneWheelOptions)
        },

        /* COUNT TOTAL ACTIVE ROW*/
        totalActiveRowCount() {
            return _.countBy(this.mapFortuneWheelOptions('status'), o => o == 1).true
        },

        /* CHECKED ALL TITLE ARRAY VALUE IS EMPTY OR NOT */
        titleRowEmptyCheck() {
            return _.some(this.mapFortuneWheelOptions('title'), _.isEmpty);
        }
    },
    watch: {
        prop_fortuneWheelOptions(newVal, oldVal) {
            this.fortuneWheelOptionsArrayFunction();
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
