<template>
    <div>
        <!-- sliderAnimate -->
        <b-row>
            <b-col md="12">
                <b-form-group>
                    <span>Animations</span>
                    <b-form-checkbox name="sliderAnimate" class="float-right" switch stacked v-model="selectedValueSliderAnimate" />
                </b-form-group>
            </b-col>

            <!-- sliderPlacement -->
            <b-col md="12">
                <b-form-group label="Placement" label-for="sliderPlacement">
                    <Select2 name="sliderPlacement" :options="sliderPlacementOptions" v-model="selectedValues.sliderPlacement" />
                </b-form-group>
            </b-col>

            <!-- sliderDisplay -->
            <b-col md="12">
                <b-form-group label="When does it display?" label-for="sliderDisplay">
                    <Select2 name="sliderDisplay" :options="barDisplayOptions" v-model="selectedValues.sliderDisplay" />
                </b-form-group>
            </b-col>

            <!-- animation_effect -->
            <b-col md="12">
                <b-form-group label="Animated Effect" label-for="animation_effect">
                    <b-form-select name="animation_effect" :options="animationEffectOptions" v-model="selectedValues.animation_effect" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect,
        },
        name: 'SliderSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { sliderPlacementOptions, barDisplayOptions, animationEffectOptions } = captureOptions();
            return {
                sliderPlacementOptions, barDisplayOptions, animationEffectOptions
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (this.prop_siteSettingArray) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },

            /* GETS THE slider animate VALUE USING THE selectedValues(). */
            selectedValueSliderAnimate: {
                get() {
                    return this.selectedValues.sliderAnimate == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.sliderAnimate = newValue == true ? 1 : 0;
                }
            },
        },
        data() {
            return {}
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.sliderAnimate) || this.prop_siteSettingArray.sliderAnimate == 0) {
                this.prop_siteSettingArray.sliderAnimate = true;
            }
            if (_.isEmpty(this.prop_siteSettingArray.sliderPlacement)) {
                this.prop_siteSettingArray.sliderPlacement = 'top-right';
            }
            if (_.isEmpty(this.prop_siteSettingArray.sliderDisplay) || this.prop_siteSettingArray.sliderDisplay == 0) {
                this.prop_siteSettingArray.sliderDisplay = '1';
            }
            if (_.isEmpty(this.prop_siteSettingArray.animation_effect)) {
                this.prop_siteSettingArray.animation_effect = 'null';
            }
        },
    }
</script>