<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-form-group label="Enter Phone number">
                    <cleave id="phone" v-model="selectedValues.setting.phoneNumber" name="phoneNumber" class="form-control" :raw="false" :options="inputMaskOptions.phone" placeholder="1234 567 8900" />
                    <br>
                    <validation-provider name="Country" rules="required">
                        <Select2 v-model="selectedValues.setting.country" :options="this.country" placeholder="Select Country" />
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>
        <br>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Ripple from 'vue-ripple-directive'
    import Cleave from 'vue-cleave-component'
    import 'cleave.js/dist/addons/cleave-phone.us'

    export default {
        directives: {
            Ripple
        },
        components: {
            BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup,
            ValidationProvider, ValidationObserver, Cleave,
        },
        name: 'talkToVisitorSetting',
        props: {
			prop_siteSettingArray: {
				type: Object,
				default: () => {},
			}
		},
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },
        },
        data() {
            return { 
                country: [
                    { text: 'US', id: 'US' },
                ],
                inputMaskOptions: {
                    phone: {
                        phone: true,
                        phoneRegionCode: 'US',
                    },
                },
            }
        },
        watch: {
            prop_siteSettingArray(settingNewVal, settingOldVal){
                this.$set(this.selectedValues, 'phoneNumber', settingNewVal.setting.phoneNumber);
                this.$set(this.selectedValues, 'country', settingNewVal.setting.country);
            },
        },
        created() {
            /** SET DEFAULT COUNTRY WHEN ADD NEW A CAPTURE */
            if (_.isEmpty(this.selectedValues.setting.country)) {
                this.selectedValues.setting.country = 'US';
            }
        }
    }
</script>