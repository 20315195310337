<template>
    <div class="pt-1">
        <!-- DISPLAY SELECTED SUBGOAL SECTION : START -->
        <b-media no-body v-show="!goalDataDivShow">
            <b-media-aside>
                <b-avatar rounded size="42" :variant="selectedGoal.avatarVariant">
                    <feather-icon size="18" :icon="selectedGoal.avatar" />
                </b-avatar>
            </b-media-aside>
            <b-media-body>
                <h6 class="transaction-title">
                    {{ selectedGoal.title }}
                </h6>
                <small>{{ selectedGoal.description }}</small>
            </b-media-body>
        </b-media>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="goalDataDivShow=true" v-show="!goalDataDivShow">
            <feather-icon icon="ArrowLeftIcon" class="mr-50" />
            <span class="align-middle">Change Goal</span>
        </b-button>
        <!-- DISPLAY SELECTED SUBGOAL SECTION : END -->

        <!-- SELECTION OF SUBGOAL SECTION : START -->
        <div v-for="(goal, goalKey) in subGoalTypeIcons" :key="goalKey" class="mb-1" v-show="goalDataDivShow">
            <b-media no-body class="cursor-pointer" @click="selectGoalDataEvent(goalKey, goal)">
                <b-media-aside>
                    <b-avatar rounded size="42" :variant="goal.avatarVariant">
                        <feather-icon size="18" :icon="goal.avatar" />
                    </b-avatar>
                </b-media-aside>
                <b-media-body>
                    <h6 class="transaction-title">
                        {{ goal.title }}
                    </h6>
                    <small>{{ goal.description }}</small>
                </b-media-body>
            </b-media>
        </div>
        <!-- SELECTION OF SUBGOAL SECTION : END -->

        <!-- CALLING DYNAMIC COMPONRNT CAPTURE SETTING WISE LIKE EMAINPHONESETTING, SOCAILSETTING : START -->
        <component :is="this.selectedGoal.componentName" v-if="siteSettingArray != {}" :prop_siteSettingArray="siteSettingArray" v-show="!goalDataDivShow"></component>
        <!-- CALLING DYNAMIC COMPONRNT CAPTURE SETTING WISE LIKE EMAINPHONESETTING, SOCAILSETTING : END -->

        <b-row>
            <b-col md="12">
                <b-form-group label="Note" label-for="Note">
                    <b-form-textarea name="Note" placeholder="Note" v-if="siteSettingArray.setting" v-model="siteSettingArray.setting.site_setting_notes" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BMediaBody, BMedia, BMediaAside, BAvatar, BButton, BFormTextarea, BFormGroup, BRow, BCol } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import captureIcons from "@/libs/capture-Icons"
    import EmailPhoneSetting from './subgoal-components/EmailPhoneSetting'
    import TalkToVisitorSetting from './subgoal-components/TalkToVisitorSetting'
    import SocialSetting from './subgoal-components/SocialSetting'
    import ClickLinkSetting from './subgoal-components/ClickLinkSetting'
    import AnnouncementSetting from './subgoal-components/AnnouncementSetting'
    import SubscribeToTextAppSetting from './subgoal-components/SubscribeToTextAppSetting'
    import FortuneWheelSetting from './subgoal-components/FortuneWheelSetting'

    export default {
        directives: {
            Ripple
        },
        components: {
            BMediaBody, BMedia, BMediaAside, BAvatar, BButton, BFormTextarea, BFormGroup, BRow, BCol,
            VuePerfectScrollbar, EmailPhoneSetting, TalkToVisitorSetting, SocialSetting, ClickLinkSetting, AnnouncementSetting, SubscribeToTextAppSetting, FortuneWheelSetting,
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        watch: {
            prop_siteSettingArray(settingNewVal) {
                this.selectGoalDataEvent(settingNewVal.setting.subGoal, this.subGoalTypeIcons[settingNewVal.setting.subGoal]);
            }
        },
        computed: {
            siteSettingArray() {
                return this.prop_siteSettingArray;
            },
        },
        data() {
            return {
                selectedGoal: {
                    title: '',
                    description: "",
                    avatar: '',
                    avatarVariant: '',
                    componentName: '',
                },
                goalDataDivShow: false,
                siteSettingNote: '',
            }
        },
        setup() {
            const { subGoalTypeIcons } = captureIcons();
            return {
                subGoalTypeIcons
            }
        },
        methods: {
            /* SELECTING SUBGOAL DATA  */
            selectGoalDataEvent(goalKey, goalData) {
                if (goalKey == 2) {
                    this.$emit('changeMobilePreview', true);
                }
                this.$set(this.prop_siteSettingArray.setting, 'subGoal', goalKey);
                this.goalDataDivShow = false 
                this.$set(this.selectedGoal, 'title', goalData.title);
                this.$set(this.selectedGoal, 'description', goalData.description);
                this.$set(this.selectedGoal, 'avatar', goalData.avatar);
                this.$set(this.selectedGoal, 'avatarVariant', goalData.avatarVariant);
                this.$set(this.selectedGoal, 'componentName', goalData.componentName);
            },
        },
    }
</script>