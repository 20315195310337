<template>
    <div>
        <!-- splitScreenDisplay -->
        <b-row>
            <b-col md="12">
                <b-form-group label="When does it display?" label-for="splitScreenDisplay">
                    <Select2 name="splitScreenDisplay" :options="this.barDisplayOptions" v-model="selectedValues.splitScreenDisplay" />
                </b-form-group>
            </b-col>

            <!-- fortuneWheelSpinTime -->
            <b-col md="12">
                <b-form-group label="Fortune Wheel Spin Time (In Second)" label-for="fortuneWheelSpinTime">
                    <b-form-input type="number" name="fortuneWheelSpinTime" v-model="selectedValues.fortuneWheelSpinTime" :formatter="formatNumber" />
                    <span class="text-warning"><strong>Note : </strong>Minimum <strong>2.5</strong> Second is required!</span>
                </b-form-group>
            </b-col>
            
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import captureOptions from "@/libs/capture-Options"

    export default {
        directives: {
            Ripple
        },
        components: {
            BRow, BCol, BFormInput, BFormGroup,
        },
        name: 'SplitScreenSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (this.prop_siteSettingArray) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },
        },
        setup() {
            const { barDisplayOptions } = captureOptions();
            return {
                barDisplayOptions
            }
        },
        data() {
            return {}
        },
        methods: {
            formatNumber(e) {
                return String(e).substring(0, 5);
            }
        },
    }
</script>