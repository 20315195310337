<template>
    <div>
        <!-- pageTakeoverAnimate -->
        <b-row>
            <b-col md="12">
                <b-form-group>
                    <span>Animations</span>
                    <b-form-checkbox name="pageTakeoverAnimate" class="float-right" switch stacked v-model="selectedValuePageTakeoverAnimate" />
                </b-form-group>
            </b-col>

            <!-- pageTakeoverDisplay -->
            <b-col md="12">
                <b-form-group label="When does it display?" label-for="pageTakeoverDisplay">
                    <Select2 name="pageTakeoverDisplay" :options="this.barDisplayOptions" v-model="selectedValues.pageTakeoverDisplay" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BFormCheckbox,
        },
        name: 'PageTakeOverSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        computed: {
            /* GETS THE PROP VALUE INTO ANOTHER FUNCTION USING COMPUTED */
            selectedValues() {
                if (this.prop_siteSettingArray) {
                    return this.prop_siteSettingArray;
                } else {
                    return [];
                }
            },

            /* GETS THE Page Take over Animate VALUE USING THE selectedValues(). */
            selectedValuePageTakeoverAnimate: {
                get() {
                    return this.selectedValues.pageTakeoverAnimate == 1 ? true : false;
                },
                set(newValue) {
                    this.selectedValues.pageTakeoverAnimate = newValue == true ? 1 : 0;
                }
            },
        },
        setup() {
            const { barDisplayOptions } = captureOptions();
            return {
                barDisplayOptions
            }
        },
        data() {
            return {}
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.pageTakeoverAnimate) || this.prop_siteSettingArray.pageTakeoverAnimate == 0) {
                this.prop_siteSettingArray.pageTakeoverAnimate = true;
            }
            if (_.isEmpty(this.prop_siteSettingArray.pageTakeoverDisplay) || this.prop_siteSettingArray.pageTakeoverDisplay == 0) {
                this.prop_siteSettingArray.pageTakeoverDisplay = '1';
            }
        },
    }
</script>