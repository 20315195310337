<template>
    <div>
        <!-- slideBarAnimate -->
        <b-row>
            <b-col md="12">
                <b-form-group>
                    <span>Animate entry/exit</span>
                    <b-form-checkbox name="slideBarAnimate" class="float-right" switch stacked v-model="selectedValuebarAnimate" />
                </b-form-group>
            </b-col>

            <!-- allowHideSlideBar -->
            <b-col md="12">
                <b-form-group>
                    <span>Allow to hide bar</span>
                    <b-form-checkbox name="allowHideSlideBar" class="float-right" switch stacked v-model="selectedValueAllowHideBar" />
                </b-form-group>
            </b-col>

            <!-- pushesPageDownSlideBar -->
            <b-col md="12">
                <b-form-group>
                    <span>Pushes page down</span>
                    <b-form-checkbox name="pushesPageDownSlideBar" class="float-right" switch stacked v-model="selectedValuePushesPageDown" />
                </b-form-group>
            </b-col>

            <!-- remainsInPlaceSlideBar -->
            <b-col md="12">
                <b-form-group>
                    <span>Remains in place</span>
                    <b-form-checkbox name="remainsInPlaceSlideBar" class="float-right" switch stacked v-model="selectedValueRemainsInPlace" />
                </b-form-group>
            </b-col>

            <!-- slideBarPlacement -->
            <b-col md="12">
                <b-form-group label="Placement" label-for="slideBarPlacement">
                    <Select2 name="slideBarPlacement" :options="barPlacementOptions" v-model="prop_siteSettingArray.slideBarPlacement" />
                </b-form-group>
            </b-col>

            <!-- slideBarSize -->
            <b-col md="12">
                <b-form-group label="Slide Bar Size" label-for="slideBarSize">
                    <Select2 name="slideBarSize" :options="barSizeOptions" v-model="prop_siteSettingArray.slideBarSize" />
                </b-form-group>
            </b-col>

            <!-- slideBarDisplay -->
            <b-col md="12">
                <b-form-group label="When does it display?" label-for="slideBarDisplay">
                    <Select2 name="slideBarDisplay" :options="barDisplayOptions" v-model="prop_siteSettingArray.slideBarDisplay" />
                </b-form-group>
            </b-col>

            <!-- animation_effect -->
            <b-col md="12">
                <b-form-group label="Animated Effect" label-for="animation_effect">
                    <b-form-select name="animation_effect" :options="animationEffectOptions" v-model="prop_siteSettingArray.animation_effect" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BFormCheckbox, BFormSelect,
        },
        name: 'SlideBarSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { barPlacementOptions, barSizeOptions, barDisplayOptions, animationEffectOptions } = captureOptions();
            return {
                barPlacementOptions, barSizeOptions, barDisplayOptions, animationEffectOptions
            }
        },
        computed: {
            /* GETS THE bar animate VALUE USING THE selectedValues(). */
            selectedValuebarAnimate: {
                get() {
                    return this.prop_siteSettingArray.slideBarAnimate == 1 ? true : false;
                },
                set(newValue) {
                    this.prop_siteSettingArray.slideBarAnimate = newValue == true ? 1 : 0;
                }
            },

            /* GETS THE allow hide bar VALUE USING THE selectedValues(). */
            selectedValueAllowHideBar: {
                get() {
                    return this.prop_siteSettingArray.allowHideSlideBar == 1 ? true : false;
                },
                set(newValue) {
                    this.prop_siteSettingArray.allowHideSlideBar = newValue == true ? 1 : 0;
                }
            },

            /* GETS THE pushes page down VALUE USING THE selectedValues(). */
            selectedValuePushesPageDown: {
                get() {
                    return this.prop_siteSettingArray.pushesPageDownSlideBar == 1 ? true : false;
                },
                set(newValue) {
                    this.prop_siteSettingArray.pushesPageDownSlideBar = newValue == true ? 1 : 0;
                }
            },

            /* GETS THE remains in place VALUE USING THE selectedValues(). */
            selectedValueRemainsInPlace: {
                get() {
                    return this.prop_siteSettingArray.remainsInPlaceSlideBar == 1 ? true : false;
                },
                set(newValue) {
                    this.prop_siteSettingArray.remainsInPlaceSlideBar = newValue == true ? 1 : 0;
                }
            },
        },
        data() {
            return {}
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.slideBarAnimate) || this.prop_siteSettingArray.slideBarAnimate == 0) {
                this.prop_siteSettingArray.slideBarAnimate = true;
            }
            if (_.isEmpty(this.prop_siteSettingArray.pushesPageDownSlideBar) || this.prop_siteSettingArray.pushesPageDownSlideBar == 0) {
                this.prop_siteSettingArray.pushesPageDownSlideBar = true;
            }
            if (_.isEmpty(this.prop_siteSettingArray.remainsInPlaceSlideBar) || this.prop_siteSettingArray.remainsInPlaceSlideBar == 0) {
                this.prop_siteSettingArray.remainsInPlaceSlideBar = true;
            }
            if (_.isEmpty(this.prop_siteSettingArray.slideBarPlacement)) {
                this.prop_siteSettingArray.slideBarPlacement = 'top';
            }
            if (_.isEmpty(this.prop_siteSettingArray.slideBarSize)) {
                this.prop_siteSettingArray.slideBarSize = 'large';
            }
            if(_.isEmpty(this.prop_siteSettingArray.slideBarDisplay) || this.prop_siteSettingArray.slideBarDisplay == 0) {
                this.prop_siteSettingArray.slideBarDisplay = '1';
            }
            if (_.isEmpty(this.prop_siteSettingArray.animation_effect)) {
                this.prop_siteSettingArray.animation_effect = 'null';
            }
        },
    }
</script>