<template>
	<b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="sidebarContactListFormChangeEvent" >
		<template #default="{ hide }">

			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					Where do you want to store the emails we collect?
				</h5>
				<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

			</div>

			<!-- BODY -->
			<validation-observer #default="{ handleSubmit }" ref="refFormObserver">

				<b-overlay :show="isFetchingContactListRecord" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

					<!-- Form -->
					<b-form class="p-2" @submit.prevent="handleSubmit(submitContactList)" @reset.prevent="sidebarContactListFormChangeEvent">

						<!-- Contact List Name -->
						<validation-provider #default="validationContext" name="Contact List Name" rules="required" :custom-messages="{ required: 'Please enter contact name'}">
							<div class="demo-vertical-spacing"></div>
							<b-form-group>
								<label label-for="contact-list-name">Contact List Name
								</label>
								<b-form-input id="contact-list-name" v-model="list_name" autofocus :state="getValidationState(validationContext)" trim placeholder="Enter List Name" autocomplete="off" />
								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- Contacts Store Type -->
						<validation-provider #default="validationContext" name="Contacts Store Type" rules="required|alpha-num">
							<b-form-group label="Where do you want your contacts stored?" label-for="contactsStoreType">
								<Select2 id="contactsStoreType" v-model="store_type" :options="contactsStoreTypeOptions" @change="contactsStoreTypeChangeEvent($event)" :settings="{'dropdownParent': '#add-new-user-sidebar' }" />
								<b-form-invalid-feedback>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>

						<!-- Drip Account Id -->
						<div v-if="store_type == 2">

							<validation-provider #default="validationContext" name="Drip Account Id" rules="required|alpha-num">
								<b-form-group label="Drip Account Id" label-for="dripAccountId">
									<b-form-input id="dripAccountId" v-model="contactListObject.drip_account_id" :state="getValidationState(validationContext)" trim @keypress="onlyNumber($event)" autocomplete="off" inputmode="numeric"/>
									<b-form-invalid-feedback>
										{{ validationContext.errors[0] }}
									</b-form-invalid-feedback>
								</b-form-group>
							</validation-provider>

							<b-form-group label="Selected Drip Campaign Name" label-for="Selected Drip Campaign Name" v-if="!$_.isEmpty(contactListObject.drip_campaign_name)">
								<b-form-input v-model="contactListObject.drip_campaign_name" :readonly="true" />
							</b-form-group>

							<b-form-group v-if="!$_.isEmpty(contactListObject.drip_account_id)">
								<div class="d-flex">
									<b-button v-if="isFetchingDripCampaign" variant="warning" disabled class="btn-sm">
										<b-spinner small />
										<span>Loading...</span>
									</b-button>
									<b-button v-if="isFetchingDripCampaign == 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-sm" type="button" @click="fetchingDripCampaign($event)">
										<span>Fetch Drip Campaigns</span>
									</b-button>
								</div>
								<span class="text-danger">NOTE: Emails won't be pushed to getdrip.com if "Drip Account Id" is wrong and the campaign is not selected. </span>
							</b-form-group>

							<div v-if="!$_.isEmpty(dripCampaignStoreOptions)">

								<validation-provider #default="validationContext" name="Campaigns" rules="required">
									<b-form-group label="Select Campaigns for contacts store" label-for="dripCampaignStore">
										<Select2 id="dripCampaignStore" v-model="contactListObject.drip_campaign_store_id" :options="dripCampaignStoreOptions" @change="dripCampaignStoreChangeEvent($event)" />
										<b-form-invalid-feedback>
											{{ validationContext.errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</validation-provider>

							</div>

						</div>

						<!-- Form Actions -->
						<div class="d-flex mt-2">
							<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"> Save </b-button>
							<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide"> Cancel </b-button>
						</div>

					</b-form>

				</b-overlay>

			</validation-observer>

		</template>
	</b-sidebar>
</template>

<script>
	import {
		BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea, BSpinner, BOverlay
	} from 'bootstrap-vue'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { ref } from '@vue/composition-api'
	import { required, alphaNum } from '@validations'
	import formValidation from '@core/comp-functions/forms/form-validation'
	import Ripple from 'vue-ripple-directive'
	import vSelect from 'vue-select'
	import store from '@/store'
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, vSelect, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea, BSpinner, BOverlay,
			ValidationProvider,
			ValidationObserver,
		},
		directives: {
			Ripple,
		},
		model: {
			prop: 'isAddNewUserSidebarActive',
			event: 'update:is-add-new-user-sidebar-active',
		},
		props: {
			isAddNewUserSidebarActive: {
				type: Boolean,
				required: true,
				default: () => false,
			},
			prop_editContactListObject: {
				type: Object,
				default: () => {},
			}
		},
		watch: {
            isAddNewUserSidebarActive() {
                if (this.isAddNewUserSidebarActive) {
                    this.store_type = this.prop_editContactListObject.store_type
                    this.list_name = this.prop_editContactListObject.list_name
					this.drip_campaign_name = this.prop_editContactListObject.drip_campaign_name;
					this.dripCampaignStoreOptions = [];
                }
            }
		},
		data() {
			return {
				required,
				alphaNum,
				isFetchingContactListRecord: false,
				contactsStoreTypeOptions: [{
					id: 1,
					text: 'In our site'
				}, {
					id: 2,
					text: 'Drip Account'
				}],
				dripCampaignStoreOptions: [],
				isFetchingDripCampaign: 0,
				store_type: null,
                list_name: ''
			}
		},
		computed: {
			contactListObject() {
				return this.prop_editContactListObject;
			},
			contactListId() {
				return this.prop_editContactListObject.contact_list_id;
			}
		},
		methods: {
			onlyNumber: function (evt) {
				evt = (evt) ? evt : window.event;
				let charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			contactsStoreTypeChangeEvent(val) {
				this.store_type = val;
			},
			dripCampaignStoreChangeEvent(val) {
				this.contactListObject.drip_campaign_store_id = val;
			},
			fetchingDripCampaign() {
				this.isFetchingContactListRecord = true;
				this.isFetchingDripCampaign = 1;
				this.getCampaignList();
			},
			getCampaignList() {
				this.$contactAPI.post('fetch-drip-campaign', {
					'auth_id': this.$root.authUserObject.id ?? '',
					'drip_account_id': this.contactListObject.drip_account_id,
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.dripCampaignStoreOptions = response.campaigns;
						/* FOR SET SELECTED IN EDIT TIME : START */
						if (_.isEmpty(this.prop_editContactListObject.drip_campaign_id) == false) {
							this.contactListObject.drip_campaign_store_id = this.prop_editContactListObject.drip_campaign_id;
						}
						/* FOR SET SELECTED IN EDIT TIME : END */
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
					this.isFetchingDripCampaign = 0;
                    this.isFetchingContactListRecord = false;
				});
			},
			submitContactList() {
				this.$contactAPI.add({
					auth_id: this.$root.authUserObject.id ?? '',
					list_name: this.list_name ?? '',
					store_type: this.store_type ?? '',
					drip_account_id: this.contactListObject.drip_account_id ?? '',
					drip_campaign_store_id: this.contactListObject.drip_campaign_store_id ?? '',
					contact_list_id: this.contactListId ?? ''
				}).then((response) => {
					response = response.data;
					if (response.status) {
						this.dripCampaignStoreOptions = response.campaigns;
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'success',
								text: response.message,
							},
						});
						this.$emit('update:is-add-new-user-sidebar-active', false) /* CLOSE SIDEBAR */
						this.$emit('refetch-data', true); /* REFRESH MAIN SITE LIST */
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
					this.isFetchingDripCampaign = 0;
					this.isFetchingContactListRecord = false;
				})
			},
			sidebarContactListFormChangeEvent(isOpen) {
				this.$emit('update:is-add-new-user-sidebar-active', isOpen);
				if (isOpen == false) {
					/* RESET ALL : START */
					this.$refs.refFormObserver.reset();
					this.contactListObject.list_name = '';
					this.contactListObject.store_type = 1;
					this.contactListObject.drip_account_id = '';
					this.contactListObject.drip_campaign_store_id = '';
					this.dripCampaignStoreOptions = [];
					/* RESET ALL : END */
				}
				this.$refs.refFormObserver.reset();
			}
		},
		setup(props, { emit }) {

			const {
				refFormObserver,
				getValidationState,
			} = formValidation({
				list_name: null,
				store_type: 1,
				drip_account_id: null,
				drip_campaign_store_id: null,
			})

			return {
				refFormObserver,
				getValidationState,
			}
		},
	}
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>