<template>
    <div>
        <br>
        <b-row>
            <b-col md="10" class="socialSetting" v-show="!socailDivShow">
                <feather-icon :icon="selectedSocial.avatar" size="22" class="mr-50 cursor-pointer" />
                <span class="align-middle cursor-pointer">{{ selectedSocial.title }}</span>
            </b-col>
            <b-col md="2" class="socialSetting" v-show="!socailDivShow">
                <feather-icon icon="XCircleIcon" size="22" class="mr-50 cursor-pointer" @click="hideExtraPara" />
            </b-col>
        </b-row>
        <b-row v-for="(socialIcon, key) in socialMedialShareTypeIcons" :key="key" v-show="socailDivShow">
            <b-col md="12" class="socialSetting cursor-pointer" @click="socialSetting(key, socialIcon)">
                <feather-icon :icon="socialIcon.avatar" size="22" class="mr-50" />
                <span class="align-middle">
                    {{key}} - {{socialIcon.title}}
                </span>
            </b-col>
        </b-row>

        <!-- Tweet on Twitter -->
        <div v-if="this.activeSocialMedia == 'tweetTwitter'">
            <b-row>
                <b-col md="12">
                    <b-form-group label="Message to tweet">
                        <b-form-input v-model="selectedValues.messageToTweet" name="messageToTweet" placeholder="Check out this great site!" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="9">
                    <p>Tweet the URL my visitor is on</p>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="twitterVisitorIsOn" name="twitterButton" switch inline />
                </b-col>
            </b-row>
            <b-row v-if="!twitterVisitorIsOn">
                <b-col md="12">
                    <b-form-group label="URL to tweet">
                        <b-form-input v-model="selectedValues.uRLToTweet" name="uRLToTweet" placeholder="Example.com" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- Follow on Twitter -->
        <div v-if="this.activeSocialMedia == 'followTwitter'">
            <b-row>
                <b-col md="12">
                    <b-form-group label="Your Twitter username">
                        <b-form-input v-model="selectedValues.twitterUsername" name="twitterUsername" placeholder="@yourtwitter" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- Facebook -->
        <div v-if="this.activeSocialMedia == 'likeFacebook'">
            <b-row>
                <b-col md="9">
                    <p>Like the URL my visitor is on</p>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="facebookURLOn" name="tweetButton" switch inline />
                </b-col>
            </b-row>
            <b-row v-if="!facebookURLOn">
                <b-col md="12">
                    <b-form-group label="URL to like">
                        <b-form-input v-model="selectedValues.facebookURLToLike" name="facebookURLToLike" placeholder="Example.com" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- LinkedIn -->
        <div v-if="this.activeSocialMedia == 'shareLinkedIn'">
            <b-row>
                <b-col md="9">
                    <p>Share the URL my visitor is on</p>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="linkedInURLOn" name="linkedinButton" switch inline />
                </b-col>
            </b-row>
            <b-row v-if="!linkedInURLOn">
                <b-col md="12">
                    <b-form-group label="URL to share">
                        <b-form-input v-model="selectedValues.linkedInURLToShare" name="linkedInURLToShare" placeholder="Example.com" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- Google+ -->
        <div v-if="this.activeSocialMedia == 'google'">
            <b-row>
                <b-col md="9">
                    <p>+1 the URL my visitor is on</p>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="googleURLOn" name="googleButton" switch inline />
                </b-col>
            </b-row>
            <b-row v-if="!googleURLOn">
                <b-col md="12">
                    <b-form-group label="URL to +1">
                        <b-form-input v-model="selectedValues.googleURLToShare" name="googleURLToShare" placeholder="Example.com" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- Pinterest -->
        <div v-if="this.activeSocialMedia == 'pinPinterest'">
            <b-row>
                <b-col md="9">
                    <p>Pin the URL my visitor is on</p>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="pinOnPinterest" name="pinterestButton" switch inline />
                </b-col>
            </b-row>
            <b-row v-if="!pinOnPinterest">
                <b-col md="12">
                    <b-form-group label="URL to pin">
                        <b-form-input v-model="selectedValues.URLToPinPinterest" name="URLToPinPinterest" placeholder="Example.com" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-form-group label="Image URL (optional)">
                        <b-form-input v-model="selectedValues.imageURLPinterest" name="imageURLPinterest" placeholder="Example.com/imeg.png" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-form-group label="Description">
                        <b-form-input v-model="selectedValues.descriptionPinterest" name="descriptionPinterest" placeholder="Check out this great site!" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- Follow on Pinterest -->
        <div v-if="this.activeSocialMedia == 'followPinterest'">
            <b-row>
                <b-col md="12">
                    <b-form-group label="Pinterest user URL">
                        <b-form-input v-model="selectedValues.pinterestUserURL" name="pinterestUserURL" placeholder="pinterest.com/pinterest" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-form-group label="Full name">
                        <b-form-input v-model="selectedValues.pinterestFullName" name="pinterestFullName" placeholder="Pindiana jones" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- Buffer -->
        <div v-if="this.activeSocialMedia == 'shareBuffer'">
            <b-row>
                <b-col md="12">
                    <b-form-group label="Message">
                        <b-form-input v-model="selectedValues.shareOnBufferMessage" name="shareOnBufferMessage" placeholder="Check out this great site!" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="9">
                    <p>Share the URL my visitor is on</p>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox v-model="shareURLOnBuffer" name="bufferButton" switch inline />
                </b-col>
            </b-row>
            <b-row v-if="!shareURLOnBuffer">
                <b-col md="12">
                    <b-form-group label="URL to share">
                        <b-form-input v-model="selectedValues.URLToShareBuffer" name="URLToShareBuffer" placeholder="Example.com" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <br>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
    import captureIcons from "@/libs/capture-Icons"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormRadioGroup, BFormGroup, BFormCheckbox
        },
        name: 'socialSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        data() {
            return {
                selectedSocial: {
                    title: 'Tweet on Twitter',
                    avatar: 'TwitterIcon',
                    name: 'tweetTwitter',
                },
                activeSocialMedia: 'tweetTwitter', // 3 get database active social media
                socailDivShow: false,
                isChecked: false,
            }
        },
        setup() {
            const { socialMedialShareTypeIcons } = captureIcons();
            return {
                socialMedialShareTypeIcons
            }
        },
        computed: {
            selectedValues() {
                if (_.get(this.prop_siteSettingArray, 'setting')) {
                    return this.prop_siteSettingArray.setting;
                } else {
                    return [];
                }
            },
            twitterVisitorIsOn: {
                get() {
                    return parseInt(this.selectedValues.visitorIsOn) == 0 ? false : true;
                },
                set(newValue) {
                    this.selectedValues.visitorIsOn = newValue == true ? 1 : 0;
                }
            },
            facebookURLOn: {
                get() {
                    return parseInt(this.selectedValues.facebookURLOn) == 0 ? false : true;
                },
                set(newValue) {
                    this.selectedValues.facebookURLOn = newValue == true ? 1 : 0;
                }
            },
            linkedInURLOn: {
                get() {
                    return parseInt(this.selectedValues.linkedInURLOn) == 0 ? false : true;
                },
                set(newValue) {
                    this.selectedValues.linkedInURLOn = newValue == true ? 1 : 0;
                }
            },
            googleURLOn: {
                get() {
                    return parseInt(this.selectedValues.googleURLOn) == 0 ? false : true;
                },
                set(newValue) {
                    this.selectedValues.googleURLOn = newValue == true ? 1 : 0;
                }
            },
            pinOnPinterest: {
                get() {
                    return parseInt(this.selectedValues.pinOnPinterest) == 0 ? false : true;
                },
                set(newValue) {
                    this.selectedValues.pinOnPinterest = newValue == true ? 1 : 0;
                }
            },
            shareURLOnBuffer: {
                get() {
                    return parseInt(this.selectedValues.shareURLOnBuffer) == 0 ? false : true;
                },
                set(newValue) {
                    this.selectedValues.shareURLOnBuffer = newValue == true ? 1 : 0;
                }
            },
        },
        methods: {
            /* ACTIVE SOCIAL MEDIA */
            socialSetting(key, activeSocial) {
                this.socailDivShow = false
                this.selectedSocial.title = activeSocial.title
                this.selectedSocial.avatar = activeSocial.avatar
                this.selectedSocial.name = activeSocial.name
                this.activeSocialMedia = activeSocial.name
                this.prop_siteSettingArray.setting.activeSocialMedia = key;
            },

            /* HIDE EXTRA PARA */
            hideExtraPara() {
                this.activeSocialMedia = '';
                this.socailDivShow = true;
            },
        },
        created() {
            if (_.isEmpty(this.selectedValues.activeSocialMedia) || this.selectedValues.activeSocialMedia == 0) {
                this.hideExtraPara()
            } else {
                var createdObj = this.socialMedialShareTypeIcons[this.selectedValues.activeSocialMedia];
                this.socialSetting(this.selectedValues.activeSocialMedia, createdObj);
            }
        },
    }
</script>

<style lang="scss">
    .socialSetting {
        padding-bottom: 20px;
    }
</style>